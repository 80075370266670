import { Box, Button, Modal, Typography } from '@mui/material';
import { FunctionComponent } from 'react';

interface IProps {
  isOpen: boolean;
  handleClose: () => void;
  handleConfirm: () => void;
}

export const CreditConfirmationModal: FunctionComponent<IProps> = ({
  isOpen,
  handleClose,
  handleConfirm,
}) => (
  <Modal open={isOpen} onClose={handleClose}>
    <Box
      sx={{
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        bgcolor: 'background.paper',
        boxShadow: 24,
        borderRadius: 1,
        py: 3,
        px: 2,
      }}
    >
      <Typography id="modal-modal-title" fontSize="25px">
        Please confirm that you have added all qualifying quarters and their
        credits.
      </Typography>
      <Box sx={{ marginTop: 1, display: 'flex' }}>
        <Button
          sx={{ flexGrow: 1, flexBasis: 0, marginRight: 0.5 }}
          variant="contained"
          color="error"
          onClick={handleConfirm}
        >
          Confirm
        </Button>
        <Button
          sx={{ flexGrow: 1, flexBasis: 0, marginLeft: 0.5 }}
          variant="outlined"
          onClick={handleClose}
        >
          Cancel
        </Button>
      </Box>
    </Box>
  </Modal>
);
