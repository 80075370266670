import {
  generateTicketsQueryKey,
  invalidateQueryIfNotMutating,
} from 'dataLayer/queryKeys';
import { errorHandler } from 'helpers/utils';
import { useQueryClient, useMutation } from 'react-query';
import { DealTicketProperties, DealTicketObject } from 'types';

export const useUpdateDealTicketObjectMutation = (
  updateTicketObjectHandler: (ticketInfo: {
    ticketId: string;
    dealTicketProperties: Partial<DealTicketProperties>;
  }) => Promise<void>,
  dealId: string,
) => {
  const qc = useQueryClient();
  const mutationKey = generateTicketsQueryKey(dealId);

  return useMutation<
    void,
    unknown,
    { ticketId: string; dealTicketProperties: Partial<DealTicketProperties> },
    { previousTicketObjects: DealTicketObject[] | undefined }
  >(updateTicketObjectHandler, {
    mutationKey,
    onMutate: ({ ticketId, dealTicketProperties: newProperties }) => {
      const previousTicketObjects: DealTicketObject[] | undefined =
        qc.getQueryData(generateTicketsQueryKey(dealId));
      qc.setQueryData(
        generateTicketsQueryKey(dealId),
        (oldTicketObjects: DealTicketObject[] | undefined) => {
          const ticketObjectBeingUpdated = oldTicketObjects?.find(
            (ticketObj) => ticketId === ticketObj.id,
          );
          if (ticketObjectBeingUpdated) {
            ticketObjectBeingUpdated.properties = {
              ...ticketObjectBeingUpdated.properties,
              ...newProperties,
            };
          }
          return oldTicketObjects ?? [];
        },
      );
      return { previousTicketObjects };
    },
    onError: (err, event, context) => {
      errorHandler(err);
      qc.setQueryData(
        generateTicketsQueryKey(dealId),
        context?.previousTicketObjects,
      );
    },
    onSettled: () => {
      invalidateQueryIfNotMutating(qc, mutationKey);
    },
  });
};
