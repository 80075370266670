import { QueryClient, QueryKey } from 'react-query';

export const invalidateQueryIfNotMutating = (
  qc: QueryClient,
  queryKey: QueryKey,
) => {
  const mutatingCount = qc.isMutating({
    mutationKey: queryKey,
  });

  if (mutatingCount === 1) {
    qc.invalidateQueries(queryKey);
  }
};

export const generateDealsQueryKey = (prefix: string) => [
  'data',
  'deals',
  prefix,
];

export const generateDealObjectQueryKey = (dealId: string) => [
  'data',
  'dealProperties',
  dealId,
];

export const generateDocumentObjectsQueryKey = (dealId: string) => [
  'data',
  'documentProperties',
  dealId,
];

export const generateERCADocumentObjectsQueryKey = (dealId: string) => [
  'data',
  'ercaDocumentProperties',
  dealId,
];

export const generateFilesQueryKey = (dealId: string, fileType: string) => [
  'data',
  'files',
  fileType,
  dealId,
];

export const generateNotesQueryKey = (dealId: string) => [
  'data',
  'notes',
  dealId,
];

export const generateTicketsQueryKey = (dealId: string) => [
  'data',
  'tickets',
  dealId,
];

export const generateBqfDisplayNamesQueryKey = () => [
  'data',
  'bqfDisplayNames',
];

export const generateInvalidReasonsQueryKey = () => ['data', 'invalidReasons'];

export const generateCreditObjectsQueryKey = (dealId: string) => [
  'data',
  'credits',
  dealId,
];

export const COGNITO_QUERY_KEY = ['cognito'];
export const COGNITO_CURRENT_USER_QUERY_KEY = ['cognito', 'currentUser'];
export const COGNITO_IS_AUTHENTICATED_QUERY_KEY = [
  'cognito',
  'isAuthenticated',
];
