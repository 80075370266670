import {
  useIsAuthenticated,
  useUserGroups,
  useLogout,
} from 'dataLayer/Cognito';
import { IDLE_TIMEOUT } from 'helpers/constants';
import { infoHandler } from 'helpers/utils';
import { BusinessQualifyForm } from 'pages/Deals/Deal/BusinessQualifyForm';
import { DealDocuments } from 'pages/Deals/Deal/DealDocuments';
import { DealCPA } from 'pages/Deals/DealCPA/DealCPA';
import { DealERCA } from 'pages/Deals/DealERCA/DealERCA';
import { DealsHome } from 'pages/Deals/DealsHome/DealsHome';
import { FileRequests } from 'pages/Deals/FileRequests/FileRequests';
import { Login } from 'pages/Login/Login';
import { FunctionComponent, Suspense, useEffect, useState } from 'react';
import { useIdleTimer } from 'react-idle-timer';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';

const DEAL = 'deal';
const DEAL_ID = ':dealId';
const DOCUMENTS = 'documents';
const BQF = 'overview';
const CPA = 'cpa';
const FILE_REQUESTS = 'file-requests';
const ERCA = 'erca';

export const PATHS = {
  DEALS: ['', DEAL].join('/'),
  DOCUMENTS: ['', DEAL, DEAL_ID, DOCUMENTS].join('/'),
  DEAL_CPA: ['', DEAL, DEAL_ID, CPA].join('/'),
  BQF: ['', DEAL, DEAL_ID, BQF].join('/'),
  FILE_REQUESTS: ['', DEAL, DEAL_ID, FILE_REQUESTS].join('/'),
  ERCA: ['', DEAL, DEAL_ID, ERCA].join('/'),
};

const AppRoutes: FunctionComponent = () => {
  const { isAuthenticated, isLoading } = useIsAuthenticated();
  const userGroups = useUserGroups();
  const { logout } = useLogout();
  const [isIdle, setIsIdle] = useState(false);

  const onIdle = () => setIsIdle(true);

  const onActive = () => setIsIdle(false);

  useIdleTimer({ onIdle, onActive, timeout: IDLE_TIMEOUT });

  useEffect(() => {
    if (isIdle && isAuthenticated) {
      logout();
      infoHandler(
        'You have been logged out due to inactivity.',
        'inactivityToast',
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isIdle]);

  return isLoading ? null : (
    <Suspense fallback={<div />}>
      <Routes>
        {!isAuthenticated && <Route path="/*" element={<Login />} />}
        {isAuthenticated && (
          <>
            <Route path={DEAL}>
              <Route index element={<DealsHome />} />
              <Route path={DEAL_ID}>
                {userGroups.includes('csc') && (
                  <Route path={DOCUMENTS} element={<DealDocuments />} />
                )}
                {userGroups.includes('cpa') && (
                  <Route path={CPA} element={<DealCPA />} />
                )}
                {userGroups.includes('erca') && (
                  <Route path={ERCA} element={<DealERCA />} />
                )}
                <Route path={BQF} element={<BusinessQualifyForm />} />
                <Route path={FILE_REQUESTS} element={<FileRequests />} />
                <Route path="" element={<Navigate to={DOCUMENTS} />} />
              </Route>
            </Route>
            <Route path="*" element={<Navigate to={DEAL} replace />} />
          </>
        )}
      </Routes>
    </Suspense>
  );
};

const Router: FunctionComponent = () => (
  <BrowserRouter>
    <AppRoutes />
  </BrowserRouter>
);

export default Router;
