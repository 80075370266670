import { CACHE_TIME, STALE_TIME } from 'helpers/constants';
import { generateFilesQueryKey } from 'dataLayer/queryKeys';
import { errorHandler } from 'helpers/utils';
import { getFilesForDeal } from './api';

export const filesQuery = (dealId: string, fileType: string) => ({
  queryKey: generateFilesQueryKey(dealId, fileType),
  queryFn: () => getFilesForDeal(dealId, fileType),
  cacheTime: CACHE_TIME,
  staleTime: STALE_TIME,
  onError: (error: unknown) => errorHandler(error),
});
