import { CACHE_TIME, STALE_TIME } from 'helpers/constants';
import { generateTicketsQueryKey } from 'dataLayer/queryKeys';
import { errorHandler } from 'helpers/utils';
import { getDealTickets } from './api';

export const ticketsQuery = (dealId: string) => ({
  queryKey: generateTicketsQueryKey(dealId),
  queryFn: () => getDealTickets(dealId),
  cacheTime: CACHE_TIME,
  staleTime: STALE_TIME,
  onError: (error: unknown) => errorHandler(error),
});
