import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { CustomDocumentUpload } from 'components/Buttons/CustomDocumentUpload';
import { TableCommonTypography } from 'components/Typography/TableCommonTypography';
import { TableHeaderTypography } from 'components/Typography/TableHeaderTypography';
import { getDocumentTableStyles } from 'pages/Deals/Deal/documentsTableStyles';
import { FunctionComponent } from 'react';
import { DocumentObject } from 'types';
import { DocumentTableCells } from './TableCells/DocumentTableCells';

interface IProps {
  dealId: string;
  documents?: DocumentObject[];
}

export const OtherDocumentsTable: FunctionComponent<IProps> = ({
  dealId,
  documents,
}) => {
  const {
    dataStatusColumnStyle,
    documentNameColumnStyle,
    dataAssociationColumnStyle,
    invalidReasonsColumnStyle,
    noteColumnStyle,
    rotateFileColumnStyle,
    statusColumnStyle,
    uploadDateColumnStyle,
  } = getDocumentTableStyles('customObjectStyle');

  return (
    <>
      <Typography
        fontSize="25px"
        fontWeight="800"
        marginTop={4}
        marginBottom={2}
      >
        Other
      </Typography>
      <>
        <CustomDocumentUpload dealId={dealId} />
        <TableContainer>
          <Table>
            <TableHead
              sx={{
                height: '75px',
              }}
            >
              <TableRow>
                <TableCell sx={statusColumnStyle} />
                <TableCell sx={documentNameColumnStyle}>
                  <TableHeaderTypography text="Document Name" />
                  <TableCommonTypography
                    text="Upload File Name"
                    sx={{ paddingTop: 1 }}
                  />
                </TableCell>
                <TableCell sx={uploadDateColumnStyle}>
                  <TableHeaderTypography text="Uploaded" />
                </TableCell>
                <TableCell sx={rotateFileColumnStyle}>
                  <TableHeaderTypography text="Rotate" />
                  <TableCommonTypography
                    text="JPEG, PNG, PDF"
                    sx={{ paddingTop: 1 }}
                  />
                </TableCell>
                <TableCell sx={dataAssociationColumnStyle}>
                  <TableHeaderTypography text="Data Association" />
                </TableCell>
                <TableCell sx={dataStatusColumnStyle}>
                  <TableHeaderTypography text="Change Status" />
                </TableCell>
                <TableCell sx={invalidReasonsColumnStyle}>
                  <TableHeaderTypography text="Reason Invalidated" />
                </TableCell>
                <TableCell sx={noteColumnStyle} />
              </TableRow>
            </TableHead>
            <TableBody>
              {documents?.map((document) => (
                <TableRow
                  key={document.properties.hubspotObjectId}
                  sx={{
                    boxShadow: '0px 1px 0px lightgray',
                  }}
                >
                  <DocumentTableCells
                    documentObject={document}
                    dealId={dealId}
                    isCustomObject
                    isReadOnly={false}
                  />
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </>
    </>
  );
};
