import {
  Box,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { DownloadButton } from 'components/Buttons/DownloadButton';
import { UploadButton } from 'components/Buttons/UploadButton';
import { LoadingIndicator } from 'components/LoadingIndicator/LoadingIndicator';
import { CreditConfirmationModal } from 'components/Modals/CreditConfirmationModal';
import { CPACreditTableCells } from 'components/Tables/TableCells/CPACreditTableCells';
import { TableCommonTypography } from 'components/Typography/TableCommonTypography';
import { TableHeaderTypography } from 'components/Typography/TableHeaderTypography';
import { creditObjectsQuery } from 'dataLayer/Credit/get';
import { updateDealProperties } from 'dataLayer/Deal/api';
import { documentObjectsQuery } from 'dataLayer/Document/get';
import { uploadCPAFile } from 'dataLayer/Files/api';
import { filesQuery } from 'dataLayer/Files/get';
import { useS3FileUploadMutation } from 'dataLayer/Files/mutations';
import { CREDIT_OBJECT_TYPES } from 'helpers/constants';
import { dateToYYYYMMDD, successHandler } from 'helpers/utils';
import { filter, find, isEmpty, map } from 'lodash';
import {
  ChangeEvent,
  FunctionComponent,
  useCallback,
  useMemo,
  useState,
} from 'react';
import NumberFormat from 'react-number-format';
import { useQuery } from 'react-query';
import { dealPropertiesQuery } from 'dataLayer/Deal/get';
import {
  checkboxColumnStyle,
  creditObjectNameColumnStyle,
  creditObjectQualifyingCreditsColumnStyle,
  creditObjectQualifyingWagesColumnStyle,
} from '../../pages/Deals/DealCPA/formTableStyles';

interface IProps {
  dealId: string;
}

const FileUploadBox: FunctionComponent<{
  label: string;
  dealId: string;
  filePrefix: string;
  uploadCallback: () => Promise<void>;
}> = ({ label, dealId, filePrefix, uploadCallback }) => {
  const { data: files } = useQuery(filesQuery(dealId, 'taxDocuments'));

  const currentFile = useMemo(() => {
    const filesWithFileName =
      files?.filter((file) =>
        file.name.includes(`${filePrefix} - ${dealId}`),
      ) ?? [];
    // TODO: Is this whole thing actually needed? Do we ever have more than one file?
    if (filesWithFileName.length) {
      filesWithFileName.sort((file1, file2) => {
        const modifiedDateFile1 = new Date(file1.lastModified);
        const modifiedDateFile2 = new Date(file2.lastModified);
        return modifiedDateFile1.valueOf() - modifiedDateFile2.valueOf();
      });
      return filesWithFileName.pop();
    }
    return undefined;
  }, [files, filePrefix, dealId]);

  const uploadFileHandler = async (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      await uploadCPAFile(dealId, file, 'taxDocuments', filePrefix);
      await uploadCallback();
      successHandler('File successfully uploaded!');
    }
  };

  const uploadFileMutation = useS3FileUploadMutation(
    uploadFileHandler,
    dealId,
    'taxDocuments',
  );

  return (
    <Box>
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <Typography
          fontSize="20px"
          color="portalPalettes.darkgray"
          fontWeight="700"
        >
          {label}
        </Typography>
        <UploadButton mutationMethod={uploadFileMutation} />
      </Box>
      {currentFile && (
        <DownloadButton
          dealId={dealId}
          fileInformation={{
            fileName: currentFile.name,
            fileType: 'taxDocuments',
          }}
          isUploading={uploadFileMutation.isLoading}
        />
      )}
    </Box>
  );
};

export const CreditsTable: FunctionComponent<IProps> = ({ dealId }) => {
  const [isCreditConfirmationOpen, setIsCreditConfirmationOpen] =
    useState(false);

  const { data: documentObjects } = useQuery(documentObjectsQuery(dealId));
  const { data: dealProperties } = useQuery(dealPropertiesQuery(dealId));
  const { data: creditObjects, isLoading: isLoadingCreditObjects } = useQuery(
    creditObjectsQuery(dealId),
  );

  const executableUploadVisible = useMemo(
    () => !!dealProperties?.properties.n941xUploadDate,
    [dealProperties],
  );

  const amended941xUploadCallback = useCallback(async () => {
    if (!dealProperties?.properties.n941xUploadDate) {
      await updateDealProperties([dealId], {
        n941xUploadDate: dateToYYYYMMDD(new Date()),
      });
    }
  }, [dealProperties?.properties.n941xUploadDate, dealId]);

  const executed941xUploadCallback = useCallback(async () => {
    if (!dealProperties?.properties.executed941xUploadDate) {
      await updateDealProperties([dealId], {
        executed941xUploadDate: dateToYYYYMMDD(new Date()),
      });
    }
  }, [dealProperties?.properties.executed941xUploadDate, dealId]);

  const customerCopiesCallback = useCallback(async () => {
    await updateDealProperties([dealId], {
      customerCopiesReceived: 'Yes',
    });
  }, [dealId]);

  const n3911Callback = useCallback(async () => {
    if (!dealProperties?.properties.n3911UploadDate) {
      await updateDealProperties([dealId], {
        n3911UploadDate: dateToYYYYMMDD(new Date()),
      });
    }
  }, [dealProperties?.properties.n3911UploadDate, dealId]);

  const taxCreditReportCallback = useCallback(async () => {
    await updateDealProperties([dealId], {
      taxCreditReportReceived: 'Yes',
    });
  }, [dealId]);

  const credit941Map = useMemo(() => {
    if (!documentObjects || !creditObjects) return undefined;
    const creditMap = map(CREDIT_OBJECT_TYPES.slice(0, 7), (cred) => {
      const quarter = cred.displayName.match(/Q\d/);
      const year = cred.displayName.match(/\d{4}/);
      const docDetailedName = `${quarter} IRS Form 941 [${year}]`;

      const hubspotDoc = find(
        documentObjects,
        (document) => document.properties.displayName === docDetailedName,
      );

      const creditObject = find(
        creditObjects,
        (credit) => credit.properties.name === cred.hubspotName,
      );

      return {
        creditId: creditObject?.id,
        hubspotDocId: hubspotDoc?.id,
        hubspotDocName: `${creditObject?.id}_${dealId}_${year}_${quarter}_941`,
        s3FileName: hubspotDoc?.properties.s3FileName,
      };
    });

    return filter(
      creditMap,
      (credit) =>
        !isEmpty(credit.creditId) &&
        !isEmpty(credit.hubspotDocId) &&
        !isEmpty(credit.s3FileName),
    ) as {
      creditId: string;
      hubspotDocId: string;
      hubspotDocName: string;
      s3FileName: string;
    }[];
  }, [documentObjects, creditObjects, dealId]);

  const onConfirmCreditAmounts = useCallback(async () => {
    if (!credit941Map) {
      console.error('no credits to confirm');
      setIsCreditConfirmationOpen(false);
      return;
    }

    try {
      setIsCreditConfirmationOpen(false);
      // Nanonets verification disabled https://trello.com/c/pL2BEur1/267-shut-down-941-flow-to-nanonets
      // await confirmCreditAmounts(credit941Map, dealId);
    } catch (error) {
      console.error(error);
    }
  }, [credit941Map]);

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          marginTop: 4,
          marginBottom: 2,
          justifyContent: 'space-between',
          gap: '32px',
        }}
      >
        <FileUploadBox
          label="941x / 943x Forms"
          dealId={dealId}
          filePrefix="Amended941x"
          uploadCallback={amended941xUploadCallback}
        />
        {executableUploadVisible && (
          <FileUploadBox
            label="Executed 941x"
            dealId={dealId}
            filePrefix="Executed941x"
            uploadCallback={executed941xUploadCallback}
          />
        )}

        <FileUploadBox
          label="Tax Credit Report"
          dealId={dealId}
          filePrefix="TaxCreditReport"
          uploadCallback={taxCreditReportCallback}
        />

        <FileUploadBox
          label="Customer Copies"
          dealId={dealId}
          filePrefix="CustomerCopies"
          uploadCallback={customerCopiesCallback}
        />

        <FileUploadBox
          label="3911 Form"
          dealId={dealId}
          filePrefix="3911"
          uploadCallback={n3911Callback}
        />
      </Box>
      {isLoadingCreditObjects ? (
        <LoadingIndicator />
      ) : (
        <TableContainer>
          <Table>
            <TableHead
              sx={{
                height: '75px',
              }}
            >
              <TableRow>
                <TableCell sx={checkboxColumnStyle.containerStyle}>
                  <TableHeaderTypography text="Qualifies" />
                  <TableHeaderTypography text="for credit" />
                </TableCell>
                <TableCell sx={creditObjectNameColumnStyle.containerStyle}>
                  <TableHeaderTypography text="Form Name" />
                  <TableCommonTypography
                    text="Upload Document Name"
                    sx={{ paddingTop: 1 }}
                  />
                </TableCell>
                <TableCell
                  sx={creditObjectQualifyingCreditsColumnStyle.containerStyle}
                >
                  <TableHeaderTypography text="Confirm Qualifying Credits" />
                </TableCell>
                <TableCell
                  sx={creditObjectQualifyingWagesColumnStyle.containerStyle}
                >
                  <TableHeaderTypography text="Confirm Qualifying Wages" />
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {CREDIT_OBJECT_TYPES.map((creditObjectType) => (
                <TableRow
                  key={creditObjectType.hubspotName}
                  sx={{
                    boxShadow: '0px 1px 0px lightgray',
                  }}
                >
                  <CPACreditTableCells
                    dealId={dealId}
                    creditObjectType={creditObjectType}
                    creditObjects={creditObjects}
                  />
                </TableRow>
              ))}
              <TableRow key="refund-total" sx={{ backgroundColor: '#F9F9F9' }}>
                <TableCell />

                {/* Row Title */}
                <TableCell>
                  <Typography
                    fontSize="20px"
                    color="portalPalettes.darkgray"
                    fontWeight="700"
                  >
                    Total qualifying credits
                  </Typography>
                </TableCell>

                {/* Total estimated refund amount  */}
                <TableCell>
                  <NumberFormat
                    thousandSeparator
                    decimalSeparator="."
                    decimalScale={2}
                    fixedDecimalScale
                    prefix="$"
                    style={{
                      fontSize: '20px',
                      color: 'portalPalettes.darkgray',
                      fontWeight: '700',
                    }}
                    displayType="text"
                    value={
                      creditObjects?.reduce(
                        (previousValue: number, creditObject) =>
                          previousValue +
                          parseFloat(
                            `${
                              creditObject.properties.estimatedRefundAmount ?? 0
                            }`,
                          ),
                        0,
                      ) ?? 0
                    }
                  />
                </TableCell>
                <TableCell>
                  <Button
                    variant="outlined"
                    sx={{
                      marginRight: 2,
                      color: 'portalPalettes.green',
                      borderColor: 'portalPalettes.green',
                      fontSize: '16px',
                    }}
                    disabled={!credit941Map}
                    onClick={() => setIsCreditConfirmationOpen(true)}
                  >
                    Confirm Credit Amounts
                  </Button>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      )}
      <CreditConfirmationModal
        isOpen={isCreditConfirmationOpen}
        handleClose={() => setIsCreditConfirmationOpen(false)}
        handleConfirm={onConfirmCreditAmounts}
      />
    </>
  );
};
