import axios, { AxiosError } from 'axios';
import { getUserToken } from 'dataLayer/Cognito';
import { lambdaAxios, generateHeaders } from 'helpers/utils';
import { GetFilesLambdaResponse, S3File } from 'types';

const FILES_URL = '/files';
const ATTACH_FILE_URL = '/attach';
const ZIP_URL = '/zip';
const ARCHIVE_FILE_URL = '/archive';
const ROTATE_FILE_URL = '/rotate';
const COPY_FILE_URL = '/copy';

const getUniqueS3ObjectPath = async (
  token: string | undefined,
  dealId: string,
  fileName: string,
  fileType: string,
  index = 0,
): Promise<{ fileName: string; putUrl: string; getUrl: string }> => {
  const fileNameSplit = fileName.split('.');
  const fileExtension = fileNameSplit.pop();
  const uniqueKey = index
    ? `${fileNameSplit.join('.')} (${index}).${fileExtension}`
    : fileName;

  try {
    const encodedFilename = encodeURIComponent(uniqueKey);
    const response = await lambdaAxios.put(
      `${FILES_URL}/${dealId}/${encodedFilename}?fileType=${fileType}`,
      undefined,
      generateHeaders(token!),
    );
    return { fileName: uniqueKey, ...response.data };
  } catch (error) {
    if ((error as AxiosError)?.response?.status === 409) {
      return getUniqueS3ObjectPath(
        token,
        dealId,
        fileName,
        fileType,
        index + 1,
      );
    }
    throw error;
  }
};

const attachFileToHubspotDeal = async (
  dealId: string,
  fileName: string,
  fileType: string,
) => {
  const token = await getUserToken();
  if (!token) return undefined;

  const encodedFilename = encodeURIComponent(fileName);
  const response = await lambdaAxios.post(
    `${ATTACH_FILE_URL}/${dealId}/${encodedFilename}?fileType=${fileType}`,
    undefined,
    generateHeaders(token),
  );
  return response.data;
};

export const uploadFile = async (
  dealId: string,
  fileType: string,
  file: File,
) => {
  const token = await getUserToken();
  if (!token) return '';
  const response = await getUniqueS3ObjectPath(
    token,
    dealId,
    file.name,
    fileType,
  );

  const buffer = await file.arrayBuffer();
  await axios.put(response.putUrl, buffer, {
    headers: { 'Content-Type': file.type },
  });

  return response.fileName;
};

export const uploadCPAFile = async (
  dealId: string,
  file: File,
  fileType: 'documents' | 'taxDocuments' | 'statementOfFacts',
  filePrefix: string,
  shouldAttachToHubspotDeal?: boolean,
) => {
  const token = await getUserToken();
  if (!token) return '';
  const extension = file.name.split('.').pop();
  const fileName = `${filePrefix} - ${dealId}.${extension}`;
  const encodedFilename = encodeURIComponent(fileName);
  const response = await lambdaAxios.put(
    `${FILES_URL}/${dealId}/${encodedFilename}?fileType=${fileType}&allowOverride=true`,
    undefined,
    generateHeaders(token!),
  );

  const buffer = await file.arrayBuffer();
  await axios.put(response.data.putUrl, buffer, {
    headers: { 'Content-Type': file.type },
  });

  if (shouldAttachToHubspotDeal)
    await attachFileToHubspotDeal(dealId, fileName, fileType);

  return fileName;
};

export const getFilesForDeal = async (dealId: string, fileType: string) => {
  const token = await getUserToken();
  if (!token) return [];

  const response = await lambdaAxios.get<GetFilesLambdaResponse>(
    `${FILES_URL}/${dealId}?fileType=${fileType}`,
    generateHeaders(token),
  );
  return response.data.files;
};

export const getFile = async (
  dealId: string,
  fileName: string,
  fileType: string,
) => {
  const token = await getUserToken();
  if (!token) return undefined;

  const encodedFilename = encodeURIComponent(fileName);
  const response = await lambdaAxios.get<S3File>(
    `${FILES_URL}/${dealId}/${encodedFilename}?fileType=${fileType}`,
    generateHeaders(token),
  );
  return response.data;
};

export const getZippedFiles = async (dealId: string, fileType: string) => {
  const token = await getUserToken();
  if (!token) return null;

  const response = await lambdaAxios.get<{ url: string }>(
    `${ZIP_URL}/${dealId}?fileType=${fileType}`,
    generateHeaders(token),
  );
  return response.data;
};

export const archiveFile = async (
  dealId: string,
  fileName: string,
  fileType: string,
) => {
  const token = await getUserToken();
  if (!token) return;

  const encodedFilename = encodeURIComponent(fileName);
  await lambdaAxios.put(
    `${ARCHIVE_FILE_URL}/${dealId}/${encodedFilename}?fileType=${fileType}`,
    undefined,
    generateHeaders(token),
  );
};

export const rotateFile = async (
  dealId: string,
  fileName: string,
  rotationInDegrees: string,
  fileType: string,
) => {
  const token = await getUserToken();
  if (!token) return;

  const encodedFilename = encodeURIComponent(fileName);
  await lambdaAxios.post(
    `${ROTATE_FILE_URL}/${dealId}/${encodedFilename}/${rotationInDegrees}?fileType=${fileType}`,
    undefined,
    generateHeaders(token),
  );
};

export const copyFile = async (
  dealId: string,
  fileName: string,
  sourceFileType: string,
  destinationFileType: string,
) => {
  const token = await getUserToken();
  if (!token) return;

  const encodedFilename = encodeURIComponent(fileName);
  await lambdaAxios.put(
    `${COPY_FILE_URL}/${dealId}/${encodedFilename}?sourceFileType=${sourceFileType}&destinationFileType=${destinationFileType}`,
    undefined,
    generateHeaders(token),
  );
};
