import { Auth } from '@aws-amplify/auth';
import { Box, Button } from '@mui/material';
import { FunctionComponent } from 'react';
import { RouteProps } from 'react-router';
import { Footer } from 'components/Footer/Footer';
import { Header } from 'components/Header/Header';

export const Login: FunctionComponent<RouteProps> = () => {
  const renderLogin = () => (
    <>
      {/* {process.env.REACT_APP_COGNITO_USER_POOL_PROVIDER_NAME && (
        <Button
          onClick={() => {
            Auth.federatedSignIn({
              customProvider:
                process.env.REACT_APP_COGNITO_USER_POOL_PROVIDER_NAME ||
                'UNDEFINED',
            });
          }}
          size="large"
          variant="outlined"
          sx={{ alignSelf: 'center', marginTop: 2 }}
        >
          Sign In with Okta
        </Button>
      )} */}

      {process.env.REACT_APP_COGNITO_USER_POOL_DOMAIN_PREFIX && (
        <Button
          onClick={() => {
            Auth.federatedSignIn();
          }}
          size="large"
          variant="outlined"
          sx={{ alignSelf: 'center', marginTop: 2 }}
        >
          Sign In
        </Button>
      )}
    </>
  );

  return (
    <>
      <Header />
      <Box
        sx={{
          padding: 3,
          paddingX: '25%',
          height: '70vh',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        {renderLogin()}
      </Box>
      <Footer />
    </>
  );
};
