import { Box, Checkbox, TableCell } from '@mui/material';
import { useDebouncedCallback } from '@react-hookz/web';
import { CurrencyInput } from 'components/Currency/CurrencyInput';
import { LoadingIndicator } from 'components/LoadingIndicator/LoadingIndicator';
import { DeleteCreditModal } from 'components/Modals/DeleteCreditModal';
import { TableBoldedTypography } from 'components/Typography/TableBoldedTypography';
import {
  createCreditObject,
  deleteCreditObject,
  updateCreditObjects,
} from 'dataLayer/Credit/api';
import {
  useCreateCreditObjectMutation,
  useCreditObjectMutation,
  useDeleteCreditObjectMutation,
} from 'dataLayer/Credit/mutations';
import { isBefore, sub } from 'date-fns';
import {
  checkboxColumnStyle,
  creditObjectNameColumnStyle,
  creditObjectQualifyingCreditsColumnStyle,
  creditObjectQualifyingWagesColumnStyle,
} from 'pages/Deals/DealCPA/formTableStyles';
import { FunctionComponent, useMemo, useState } from 'react';
import { CreditObject, CreditObjectProperties } from 'types';

interface IProps {
  dealId: string;
  creditObjectType: { displayName: string; hubspotName: string };
  creditObjects?: CreditObject[];
  isReadOnly?: boolean;
}

export const CPACreditTableCells: FunctionComponent<IProps> = ({
  dealId,
  creditObjectType,
  creditObjects,
  isReadOnly,
}) => {
  const creditObjectForThisForm = useMemo(
    () =>
      creditObjects?.find(
        (creditObject) =>
          creditObject.properties.name === creditObjectType.hubspotName,
      ),
    [creditObjects, creditObjectType],
  );

  const isLocked = useMemo(() => {
    if (!creditObjectForThisForm) return false;
    const creditDate = new Date(creditObjectForThisForm.createdAt);
    return isBefore(creditDate, sub(Date.now(), { hours: 1 }));
  }, [creditObjectForThisForm]);

  const createCreditObjectHandler = async (
    creditProperties: Partial<CreditObjectProperties>,
  ) => {
    await createCreditObject(dealId, creditProperties);
  };

  const createCreditObjectMutation = useCreateCreditObjectMutation(
    createCreditObjectHandler,
    dealId,
  );

  const deleteCreditObjectHandler = async (creditId: string) => {
    if (creditObjectForThisForm) await deleteCreditObject(dealId, creditId);
  };

  const deleteCreditObjectMutation = useDeleteCreditObjectMutation(
    deleteCreditObjectHandler,
    dealId,
  );

  const updateCreditObjectHandler = async (
    creditProperties: Partial<CreditObjectProperties>,
  ) => {
    if (creditObjectForThisForm) {
      await updateCreditObjects([creditObjectForThisForm.id], creditProperties);
    }
  };

  const updateCreditObjectMutation = useCreditObjectMutation(
    updateCreditObjectHandler,
    dealId,
  );

  const updateEstimatedRefundAmount = useDebouncedCallback(
    (input) =>
      updateCreditObjectMutation.mutate({
        name: creditObjectForThisForm?.properties.name,
        estimatedRefundAmount: input,
      }),
    [],
    1000,
    0,
  );

  const updateEstimatedRefundAmountOverride = useDebouncedCallback(
    (email: string, reason: string, input) =>
      updateCreditObjectMutation.mutate({
        name: creditObjectForThisForm?.properties.name,
        estimatedRefundAmount: input,
        changeInQualifyingCreditAmountReason: reason,
        taxPreparerEmail: email,
      }),
    [],
    1000,
    0,
  );

  const updateQualifyingWages = useDebouncedCallback(
    (input) =>
      updateCreditObjectMutation.mutate({
        name: creditObjectForThisForm?.properties.name,
        qualifyingWages: input,
      }),
    [],
    1000,
    0,
  );

  const [isModalOpen, setIsModalOpen] = useState(false);

  return (
    <>
      {/* Column 1: Checkbox */}
      <TableCell sx={checkboxColumnStyle.containerStyle}>
        {createCreditObjectMutation.isLoading ||
        deleteCreditObjectMutation.isLoading ||
        creditObjectForThisForm?.id === 'optimisticId' ? (
          <LoadingIndicator />
        ) : (
          <Checkbox
            color="default"
            checked={!!creditObjectForThisForm ?? false}
            disabled={isReadOnly}
            onChange={(event) => {
              if (event.target.checked) {
                createCreditObjectMutation.mutate({
                  name: creditObjectType.hubspotName,
                });
              } else {
                setIsModalOpen(true);
              }
            }}
          />
        )}
      </TableCell>

      {/* Column 2: Form Name */}
      <TableCell
        sx={{
          ...creditObjectNameColumnStyle.containerStyle,
        }}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <TableBoldedTypography text={creditObjectType.displayName} />
        </Box>
      </TableCell>

      {/* Column 3: Confirm Qualifying Credits */}
      <TableCell
        sx={{
          ...creditObjectQualifyingCreditsColumnStyle.containerStyle,
        }}
      >
        {!!creditObjectForThisForm && !createCreditObjectMutation.isLoading && (
          <CurrencyInput
            isReadOnly={isReadOnly}
            disabled={isLocked}
            defaultValue={
              creditObjectForThisForm.properties.estimatedRefundAmount
            }
            onChange={updateEstimatedRefundAmount}
            onChangeOverride={updateEstimatedRefundAmountOverride}
          />
        )}
      </TableCell>

      {/* Column 4: Confirm Qualifying Wages */}
      <TableCell
        sx={{
          ...creditObjectQualifyingWagesColumnStyle.containerStyle,
        }}
      >
        {!!creditObjectForThisForm && !createCreditObjectMutation.isLoading && (
          <CurrencyInput
            isReadOnly={isReadOnly}
            defaultValue={creditObjectForThisForm.properties.qualifyingWages}
            onChange={updateQualifyingWages}
          />
        )}
      </TableCell>

      <DeleteCreditModal
        isOpen={isModalOpen}
        setIsOpen={setIsModalOpen}
        onConfirm={() => {
          if (creditObjectForThisForm)
            deleteCreditObjectMutation.mutate(creditObjectForThisForm.id);
          setIsModalOpen(false);
        }}
      />
    </>
  );
};
