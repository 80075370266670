import {
  Box,
  Button,
  InputLabel,
  Modal,
  TextField,
  Typography,
} from '@mui/material';
import { isEmpty, isEqual } from 'lodash';
import { FunctionComponent, useState, FocusEvent } from 'react';
import NumberFormat from 'react-number-format';

interface IProps {
  isOpen: boolean;
  handleClose: () => void;
  handleConfirm: (email: string, reason: string, value: string) => void;
  currentValue?: number;
}

export const UpdateCreditModal: FunctionComponent<IProps> = ({
  isOpen,
  currentValue,
  handleClose,
  handleConfirm,
}) => {
  const [reason, setReason] = useState('');
  const [email, setEmail] = useState('');
  const [value, setValue] = useState(String(currentValue!));
  return (
    <Modal open={isOpen} onClose={handleClose}>
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: 400,
          bgcolor: 'background.paper',
          boxShadow: 24,
          borderRadius: 1,
          py: 3,
          px: 2,
        }}
      >
        <Typography id="modal-modal-title" fontSize="25px">
          Please provide a reason for the update.
        </Typography>
        <TextField
          sx={{ marginTop: '8px' }}
          fullWidth
          label="Tax Preparer Email"
          multiline
          value={email}
          onChange={(event) => {
            setEmail(event.target.value);
          }}
        />
        <TextField
          sx={{ marginTop: '8px' }}
          fullWidth
          multiline
          label="Reason"
          rows={4}
          value={reason}
          onChange={(event) => {
            setReason(event.target.value);
          }}
        />
        <InputLabel sx={{ marginTop: '8px' }} id="rejection-notes-id">
          New Amount
        </InputLabel>
        <Box sx={{ display: 'flex', marginBottom: '8px' }}>
          <Box
            sx={{
              borderRadius: '4px 0 0 4px',
              borderStyle: 'solid',
              borderWidth: '1px 0 1px 1px',
              borderColor: '#0000003B',
              height: 'inherit',
              paddingY: 1,
              paddingX: 1.5,
            }}
          >
            $
          </Box>
          <NumberFormat
            thousandSeparator
            decimalSeparator="."
            decimalScale={2}
            fixedDecimalScale
            style={{
              borderRadius: '0 4px 4px 0',
              borderStyle: 'solid',
              borderWidth: '1px',
              borderColor: '#0000003B',
              paddingLeft: '8px',
            }}
            type="text"
            defaultValue={currentValue ?? 0}
            onValueChange={(values: { value: string }) =>
              setValue(values.value)
            }
            onFocus={(event: FocusEvent<HTMLInputElement>) =>
              event.target?.select()
            }
          />
        </Box>
        <Box sx={{ marginTop: 1, display: 'flex' }}>
          <Button
            sx={{ flexGrow: 1, flexBasis: 0, marginRight: 0.5 }}
            variant="contained"
            color="error"
            disabled={
              isEmpty(reason) ||
              isEmpty(value) ||
              isEqual(value, currentValue) ||
              isEmpty(email)
            }
            onClick={() => handleConfirm(email, reason, value)}
          >
            Confirm
          </Button>
          <Button
            sx={{ flexGrow: 1, flexBasis: 0, marginLeft: 0.5 }}
            variant="outlined"
            onClick={handleClose}
          >
            Cancel
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};
