import {
  Box,
  Button,
  InputLabel,
  MenuItem,
  Modal,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import { useAsync } from '@react-hookz/web';
import { UploadButton } from 'components/Buttons/UploadButton';
import { LoadingIndicator } from 'components/LoadingIndicator/LoadingIndicator';
import { updateDealProperties } from 'dataLayer/Deal/api';
import {
  caseRejectionCategoriesQuery,
  dealPropertiesQuery,
} from 'dataLayer/Deal/get';
import { uploadCPAFile } from 'dataLayer/Files/api';
import { useS3FileUploadMutation } from 'dataLayer/Files/mutations';
import { generateDealObjectQueryKey } from 'dataLayer/queryKeys';
import { successHandler, errorHandler } from 'helpers/utils';
import { isEmpty, map } from 'lodash';
import { ChangeEvent, FunctionComponent, useState } from 'react';
import { useQuery, useQueryClient } from 'react-query';

interface IProps {
  dealId: string;
}

export const RejectCaseModal: FunctionComponent<IProps> = ({ dealId }) => {
  const qc = useQueryClient();
  const [openModal, setOpenModal] = useState(false);
  const [notes, setNotes] = useState('');
  const [rejectionCategory, setRejectionCategory] = useState('');

  const { data: rejectionCategories } = useQuery(
    caseRejectionCategoriesQuery(dealId),
  );

  const { data: dealProperties } = useQuery(dealPropertiesQuery(dealId));

  const uploadCaseRejectionReportHandler = async (
    event: ChangeEvent<HTMLInputElement>,
  ) => {
    const file = event.target.files?.[0];
    if (file) {
      await uploadCPAFile(
        dealId,
        file,
        'taxDocuments',
        'CaseRejectionReport',
        true,
      );
      await updateDealProperties([dealId], {
        caseRejectionReportReceived: 'Yes',
      });
      successHandler('File successfully uploaded!');
    }
  };

  const uploadCaseRejectionReportMutation = useS3FileUploadMutation(
    uploadCaseRejectionReportHandler,
    dealId,
    'taxDocuments',
  );

  const handleClose = () => {
    setRejectionCategory('');
    setNotes('');
    setOpenModal(false);
  };

  const [submitState, submitAction] = useAsync(async () => {
    if (isEmpty(notes) && isEmpty(rejectionCategory)) {
      errorHandler(
        new Error('Please provide a rejection category and a rejection reason'),
      );
    } else if (isEmpty(notes)) {
      errorHandler(new Error('Please enter a rejection reason.'));
    } else if (isEmpty(rejectionCategory)) {
      errorHandler(new Error('Please provide a rejection category'));
    } else if (
      !dealProperties?.properties.assignedCSCId ||
      !dealProperties?.properties.dealOwnerId
    ) {
      errorHandler(
        new Error('Deal does not have an assigned CSC or deal owner.'),
      );
    } else {
      // Update the deal with the the rejection date
      const date = new Date();
      const year = date.getFullYear().toString();
      const month = `0${(date.getMonth() + 1).toString()}`.slice(-2);
      const day = `0${date.getDate()}`.slice(-2);
      await updateDealProperties([dealId], {
        caseRejectionCategory: rejectionCategory,
        caseRejectionNotes: notes,
        taxAttorneyRejectedDate: `${year}-${month}-${day}`, // Date needs to be in this specific format
      });
      qc.invalidateQueries(generateDealObjectQueryKey(dealId));
      successHandler('Case Rejected');
      handleClose();
    }
  });

  return (
    <>
      <Button
        variant="outlined"
        color="error"
        sx={{
          marginLeft: 'auto',
          marginRight: 2,
          fontSize: '16px',
        }}
        onClick={() => {
          setNotes('');
          setOpenModal(true);
        }}
      >
        Reject Case
      </Button>
      <Modal open={openModal} onClose={handleClose}>
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 400,
            bgcolor: 'background.paper',
            boxShadow: 24,
            borderRadius: 1,
            py: 3,
            px: 2,
          }}
        >
          <Typography id="modal-modal-title" fontSize="25px">
            Please explain reason for rejection
          </Typography>
          <InputLabel id="rejection-category-id">Category</InputLabel>
          <Select
            fullWidth
            labelId="rejection-category-id"
            sx={{ marginTop: '8px' }}
            value={rejectionCategory}
            onChange={(changeEvent) =>
              setRejectionCategory(changeEvent.target.value)
            }
          >
            {map(rejectionCategories, (category) => (
              <MenuItem value={category.value} key={category.displayOrder}>
                {category.label}
              </MenuItem>
            ))}
          </Select>
          <InputLabel sx={{ marginTop: '8px' }} id="rejection-notes-id">
            Reason
          </InputLabel>
          <TextField
            sx={{ marginTop: '8px' }}
            fullWidth
            multiline
            rows={4}
            value={notes}
            onChange={(event) => {
              setNotes(event.target.value);
            }}
          />
          <Typography
            sx={{ marginTop: '8px' }}
            fontSize="16px"
            color="portalPalettes.lightgray"
          >
            Case Rejection Report
            <UploadButton mutationMethod={uploadCaseRejectionReportMutation} />
          </Typography>
          <Box sx={{ marginTop: 1, display: 'flex' }}>
            <Button
              sx={{ flexGrow: 1, flexBasis: 0, marginRight: 0.5 }}
              disabled={
                submitState.status === 'loading' ||
                !uploadCaseRejectionReportMutation.isSuccess
              }
              variant="contained"
              color="error"
              onClick={submitAction.execute}
            >
              {submitState.status === 'loading' ? (
                <LoadingIndicator size={20} />
              ) : (
                'Reject Case'
              )}
            </Button>
            <Button
              sx={{ flexGrow: 1, flexBasis: 0, marginLeft: 0.5 }}
              variant="outlined"
              onClick={handleClose}
            >
              Cancel
            </Button>
          </Box>
        </Box>
      </Modal>
    </>
  );
};
