import { ThemeOptions } from '@mui/material';

export const GLOBAL_THEME = {
  palette: {
    portalPalettes: {
      lightgreen: '#2CCF8A',
      green: '#23A56E',
      darkgray: '#3D3D3D',
      lightgray: '#5A5A5A',
    },
  },
  typography: {
    fontFamily: 'Crique Grotesk',
  },
  components: {
    MuiTableContainer: {
      styleOverrides: {
        root: {
          borderRadius: '8px',
          boxShadow: '0px 2px 8px #00000014',
        },
      },
    },
    MuiTableBody: {
      styleOverrides: {
        root: {
          backgroundColor: 'white',
        },
      },
    },
    MuiTableHead: {
      styleOverrides: {
        root: {
          backgroundColor: '#DDE4EF33',
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          backgroundColor: 'white',
        },
      },
    },
    MuiButton: {
      variants: [
        {
          props: { variant: 'outlined' },
          style: {
            backgroundColor: 'white',
          },
        },
        {
          props: { variant: 'contained' },
          style: {
            backgroundColor: '#23A56E',
            '&:hover': {
              backgroundColor: '#2CCF8A',
            },
          },
        },
      ],
      styleOverrides: {
        root: {
          textTransform: 'none',
        },
      },
    },
  },
} as ThemeOptions;
