import { Box, Typography } from '@mui/material';
import { FunctionComponent } from 'react';
import { DealHeader } from './DealHeader';

interface IProps {
  dealId: string;
}

export const SubHeader: FunctionComponent<IProps> = ({ dealId }) => (
  <>
    <DealHeader dealId={dealId} />
    <Typography fontSize="25px" fontWeight="800" marginBottom={2}>
      New Uploads
    </Typography>
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        marginBottom: 2,
      }}
    >
      <Typography fontSize="15px">
        New uploads ready for review, association and status confirmation.
      </Typography>
      <Typography fontSize="16px" color="#777777">
        All uploads and edits are saved automatically
      </Typography>
    </Box>
  </>
);
