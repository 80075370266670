import {
  generateDocumentObjectsQueryKey,
  generateERCADocumentObjectsQueryKey,
  invalidateQueryIfNotMutating,
} from 'dataLayer/queryKeys';
import { errorHandler } from 'helpers/utils';
import { ChangeEvent } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import {
  DocumentObject,
  DocumentObjectProperties,
  ERCADocumentObject,
} from 'types';

export const useDocumentObjectMutation = (
  updateDocumentObjectHandler: (documentInfo: {
    documentIds: string[];
    documentProperties: Partial<DocumentObjectProperties>;
  }) => Promise<void>,
  dealId: string,
) => {
  const qc = useQueryClient();
  const mutationKey = generateDocumentObjectsQueryKey(dealId);

  return useMutation<
    void,
    unknown,
    {
      documentIds: string[];
      documentProperties: Partial<DocumentObjectProperties>;
    },
    { previousDocumentObjects: DocumentObject[] | undefined }
  >(updateDocumentObjectHandler, {
    mutationKey,
    onMutate: ({ documentIds, documentProperties: newProperties }) => {
      const previousDocumentObjects: DocumentObject[] | undefined =
        qc.getQueryData(generateDocumentObjectsQueryKey(dealId));
      qc.setQueryData(
        generateDocumentObjectsQueryKey(dealId),
        (oldDocumentObjects: DocumentObject[] | undefined) => {
          const documentObjectsBeingUpdated = oldDocumentObjects?.filter(
            (docObj) => documentIds.includes(docObj.id),
          );
          if (documentObjectsBeingUpdated?.length) {
            documentObjectsBeingUpdated.forEach((documentObject) => {
              // eslint-disable-next-line no-param-reassign
              documentObject.properties = {
                ...documentObject.properties,
                ...newProperties,
              };
            });
          }
          return oldDocumentObjects ?? [];
        },
      );
      return { previousDocumentObjects };
    },
    onError: (err, event, context) => {
      errorHandler(err);
      qc.setQueryData(
        generateDocumentObjectsQueryKey(dealId),
        context?.previousDocumentObjects,
      );
    },
    onSettled: () => {
      invalidateQueryIfNotMutating(qc, mutationKey);
    },
  });
};

export const useDocumentObjectMutationForUploadProperties = (
  updateDocumentObjectHandler: (
    event: ChangeEvent<HTMLInputElement>,
  ) => Promise<void>,
  dealId: string,
  currentDocumentObject: DocumentObject,
) => {
  const qc = useQueryClient();
  const mutationKey = generateDocumentObjectsQueryKey(dealId);

  return useMutation<
    void,
    unknown,
    ChangeEvent<HTMLInputElement>,
    { previousDocumentObjects: DocumentObject[] | undefined }
  >(updateDocumentObjectHandler, {
    mutationKey,
    onMutate: (event) => {
      const fileName = event.target.files?.[0]?.name;
      const previousDocumentObjects: DocumentObject[] | undefined =
        qc.getQueryData(generateDocumentObjectsQueryKey(dealId));
      if (fileName) {
        qc.setQueryData(
          generateDocumentObjectsQueryKey(dealId),
          (oldDocumentObjects: DocumentObject[] | undefined) => {
            const documentObjectToOverwrite = oldDocumentObjects?.find(
              (docObj) => docObj.id === currentDocumentObject.id,
            );
            if (documentObjectToOverwrite) {
              documentObjectToOverwrite.properties = {
                ...documentObjectToOverwrite.properties,
                s3FileName: fileName,
                fileLastModifiedDate: new Date().toISOString(),
                contentType: '',
              };
            }
            return oldDocumentObjects ?? [];
          },
        );
      }
      return { previousDocumentObjects };
    },
    onError: (err, event, context) => {
      errorHandler(err);
      qc.setQueryData(
        generateDocumentObjectsQueryKey(dealId),
        context?.previousDocumentObjects,
      );
    },
    onSettled: () => {
      invalidateQueryIfNotMutating(qc, mutationKey);
    },
  });
};

export const useDocumentUploadMutation = (
  uploadFileHandler: (documentInfo: {
    file: File | undefined;
    documentName: string;
  }) => Promise<void>,
  dealId: string,
) => {
  const qc = useQueryClient();
  const mutationKey = generateDocumentObjectsQueryKey(dealId);

  return useMutation<
    void,
    unknown,
    {
      file: File | undefined;
      documentName: string;
    },
    { previousDocumentObjects: DocumentObject[] | undefined }
  >(uploadFileHandler, {
    mutationKey,
    onMutate: ({ file, documentName }) => {
      const previousDocumentObjects: DocumentObject[] | undefined =
        qc.getQueryData(generateDocumentObjectsQueryKey(dealId));
      qc.setQueryData(
        generateDocumentObjectsQueryKey(dealId),
        (oldDocumentObjects: DocumentObject[] | undefined) => {
          const newCustomDocumentObject = {
            id: 'optimistic_id',
            createdAt: new Date().toISOString(),
            updatedAt: new Date().toISOString(),
            archived: false,
            properties: {
              displayName: documentName,
              fileNamingConvention: documentName,
              s3FileName: file?.name,
              status: 'Pending IR Review',
              year: 0,
              priorityNumber: 0,
              description: null,
              hubspotCreateDate: new Date().toISOString(),
              hubspotLastModifiedDate: new Date().toISOString(),
              hubspotObjectId: 'optimistic_objectId',
              internalNotes: '',
              fileLastModifiedDate: new Date().toISOString(),
            },
          };
          oldDocumentObjects?.push(newCustomDocumentObject);
          return oldDocumentObjects ?? [];
        },
      );
      return { previousDocumentObjects };
    },
    onError: (err, event, context) => {
      errorHandler(err);
      qc.setQueryData(
        generateDocumentObjectsQueryKey(dealId),
        context?.previousDocumentObjects,
      );
    },
    onSettled: () => {
      invalidateQueryIfNotMutating(qc, mutationKey);
    },
  });
};

export const useERCADocumentMutation = (
  updateERCADocumentHandler: (documentInfo: {
    documentId: string;
    documentProperties: Partial<ERCADocumentObject>;
  }) => Promise<void>,
  dealId: string,
) => {
  const qc = useQueryClient();
  const mutationKey = generateERCADocumentObjectsQueryKey(dealId);

  return useMutation<
    void,
    unknown,
    {
      documentId: string;
      documentProperties: Partial<ERCADocumentObject>;
    },
    { previousERCADocuments: ERCADocumentObject[] | undefined }
  >(updateERCADocumentHandler, {
    mutationKey,
    onMutate: ({ documentId, documentProperties: newProperties }) => {
      const previousERCADocuments: ERCADocumentObject[] | undefined =
        qc.getQueryData(generateERCADocumentObjectsQueryKey(dealId));
      qc.setQueryData(
        generateERCADocumentObjectsQueryKey(dealId),
        (oldERCADocuments: ERCADocumentObject[] | undefined) => {
          const ercaDocumentBeingUpdatedIndex = oldERCADocuments?.findIndex(
            (document) => documentId === document.documentId,
          );
          if (ercaDocumentBeingUpdatedIndex !== undefined && oldERCADocuments) {
            // eslint-disable-next-line no-param-reassign
            oldERCADocuments[ercaDocumentBeingUpdatedIndex] = {
              ...oldERCADocuments[ercaDocumentBeingUpdatedIndex],
              ...newProperties,
            };
          }
          return oldERCADocuments ?? [];
        },
      );
      return { previousERCADocuments };
    },
    onError: (err, _event, context) => {
      errorHandler(err);
      qc.setQueryData(
        generateERCADocumentObjectsQueryKey(dealId),
        context?.previousERCADocuments,
      );
    },
    onSettled: () => {
      invalidateQueryIfNotMutating(qc, mutationKey);
    },
  });
};

export const useERCADocumentMutationForUpload = (
  updateERCADocumentHandler: (
    event: ChangeEvent<HTMLInputElement>,
  ) => Promise<void>,
  dealId: string,
  currentERCADocument: ERCADocumentObject,
) => {
  const qc = useQueryClient();
  const mutationKey = generateERCADocumentObjectsQueryKey(dealId);

  return useMutation<
    void,
    unknown,
    ChangeEvent<HTMLInputElement>,
    { previousERCADocuments: ERCADocumentObject[] | undefined }
  >(updateERCADocumentHandler, {
    mutationKey,
    onMutate: (event) => {
      const fileName = event.target.files?.[0]?.name;
      const previousERCADocuments: ERCADocumentObject[] | undefined =
        qc.getQueryData(generateERCADocumentObjectsQueryKey(dealId));
      qc.setQueryData(
        generateERCADocumentObjectsQueryKey(dealId),
        (oldERCADocuments: ERCADocumentObject[] | undefined) => {
          const ercaDocumentBeingUpdatedIndex = oldERCADocuments?.findIndex(
            (document) =>
              document.documentId === currentERCADocument.documentId,
          );
          if (ercaDocumentBeingUpdatedIndex !== undefined && oldERCADocuments) {
            // eslint-disable-next-line no-param-reassign
            oldERCADocuments[ercaDocumentBeingUpdatedIndex] = {
              ...oldERCADocuments[ercaDocumentBeingUpdatedIndex],
              fileName,
              fileLastModifiedDate: new Date().toISOString(),
            };
          }
          return oldERCADocuments ?? [];
        },
      );
      return { previousERCADocuments };
    },
    onError: (err, _event, context) => {
      errorHandler(err);
      qc.setQueryData(
        generateERCADocumentObjectsQueryKey(dealId),
        context?.previousERCADocuments,
      );
    },
    onSettled: () => {
      invalidateQueryIfNotMutating(qc, mutationKey);
    },
  });
};
