import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { DownloadButton } from 'components/Buttons/DownloadButton';
import { Footer } from 'components/Footer/Footer';
import { DealHeader } from 'components/Header/DealHeader';
import { Header } from 'components/Header/Header';
import { LoadingIndicator } from 'components/LoadingIndicator/LoadingIndicator';
import { CSCDocumentsTable } from 'components/Tables/CSCDocumentsTable';
import { ERCACreditsTable } from 'components/Tables/ERCACreditsTable';
import { ERCADocumentsTable } from 'components/Tables/ERCADocumentsTable';
import { TableBoldedTypography } from 'components/Typography/TableBoldedTypography';
import { TableCommonTypography } from 'components/Typography/TableCommonTypography';
import { TableHeaderTypography } from 'components/Typography/TableHeaderTypography';
import {
  dealPropertiesQuery,
  form8821FilesQuery,
  statementOfFactsFilesQuery,
} from 'dataLayer/Deal/get';
import { documentObjectsQuery } from 'dataLayer/Document/get';
import { ERCA_YEARS } from 'helpers/constants';
import { sortByStatus } from 'helpers/utils';
import { FunctionComponent } from 'react';
import { useQuery } from 'react-query';
import { RouteProps, useParams } from 'react-router-dom';

export const DealERCA: FunctionComponent<RouteProps> = () => {
  const { dealId } = useParams() as { dealId: string };

  const { data: dealProperties, isLoading: isLoadingDealProperties } = useQuery(
    dealPropertiesQuery(dealId),
  );
  const ercDealId = dealProperties?.properties.ercDealId ?? '';

  const { data: documentObjects, isLoading: isLoadingDocumentObjects } =
    useQuery(documentObjectsQuery(ercDealId ?? ''));

  const { data: filesFor8821Form, isLoading: isLoadingFilesFor8821Form } =
    useQuery(form8821FilesQuery(ercDealId ?? ''));

  const {
    data: filesForStatementOfFacts,
    isLoading: isLoadingFilesForStatementOfFacts,
  } = useQuery(statementOfFactsFilesQuery(ercDealId ?? ''));

  return (
    <>
      <Header />
      <DealHeader dealId={dealId} />
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          marginTop: 4,
          marginBottom: 2,
        }}
      >
        <Typography
          fontSize="20px"
          color="portalPalettes.darkgray"
          fontWeight="700"
        >
          ERCA Documents
        </Typography>
      </Box>
      <ERCADocumentsTable dealId={dealId} />
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          marginTop: 4,
          marginBottom: 2,
        }}
      >
        <Typography
          fontSize="20px"
          color="portalPalettes.darkgray"
          fontWeight="700"
        >
          Documents
        </Typography>
      </Box>
      {isLoadingDocumentObjects || isLoadingDealProperties ? (
        <LoadingIndicator />
      ) : (
        <>
          {ERCA_YEARS.map((year) => {
            if (
              year === 2023 &&
              !documentObjects?.find((doc) =>
                (doc.properties.displayName ?? '').includes('Most Recent 941'),
              )
            ) {
              return undefined;
            }
            return (
              <CSCDocumentsTable
                dealId={ercDealId}
                label={year}
                documents={documentObjects
                  ?.filter((document) => document.properties.year === year)
                  .sort(sortByStatus)}
                isReadOnly
              />
            );
          })}
          <CSCDocumentsTable
            dealId={ercDealId}
            label="Other"
            documents={documentObjects
              ?.filter(
                (document) => !ERCA_YEARS.includes(document.properties.year),
              )
              .sort(sortByStatus)}
            isReadOnly
          />
        </>
      )}
      <ERCACreditsTable
        dealId={ercDealId}
        isLoadingDealProperties={isLoadingDealProperties}
      />
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          marginTop: 4,
          marginBottom: 2,
        }}
      >
        <Typography
          fontSize="20px"
          color="portalPalettes.darkgray"
          fontWeight="700"
        >
          8821 Form / Statement of Facts
        </Typography>
      </Box>
      <TableContainer>
        <Table>
          <TableHead
            sx={{
              height: '75px',
            }}
          >
            <TableRow>
              <TableCell>
                <TableHeaderTypography text="Form Name" />
                <TableCommonTypography
                  text="Upload Document Name"
                  sx={{ paddingTop: 1 }}
                />
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell>
                {isLoadingFilesFor8821Form || isLoadingDealProperties ? (
                  <LoadingIndicator />
                ) : (
                  <>
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                      <TableBoldedTypography text="8821 Form" />
                    </Box>
                    {!!filesFor8821Form?.[0]?.name && (
                      <DownloadButton
                        dealId={ercDealId}
                        fileInformation={{
                          fileName: filesFor8821Form[0].name,
                          fileType: '8821',
                        }}
                      />
                    )}
                  </>
                )}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                {isLoadingFilesForStatementOfFacts ||
                isLoadingDealProperties ? (
                  <LoadingIndicator />
                ) : (
                  <>
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                      <TableBoldedTypography text="Statement of Facts" />
                    </Box>
                    {!!filesForStatementOfFacts?.[0]?.name && (
                      <DownloadButton
                        dealId={ercDealId}
                        fileInformation={{
                          fileName: filesForStatementOfFacts[0].name,
                          fileType: 'statementOfFacts',
                        }}
                      />
                    )}
                  </>
                )}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
      <Footer />
    </>
  );
};
