import { getUserToken } from 'dataLayer/Cognito';
import { uploadFile } from 'dataLayer/Files/api';
import { generateHeaders, lambdaAxios } from 'helpers/utils';
import {
  DocumentObject,
  DocumentObjectProperties,
  ERCADocumentObject,
  InvalidReason,
} from 'types';

const HUBSPOT_DOCUMENTS_URL = '/hubspot/documents';
const HUBSPOT_ERCA_URL = '/hubspot/erca';
const HUBSPOT_INVALIDREASONS_URL = '/hubspot/invalidreasons';

export const getDocumentProperties = async (dealId: string) => {
  const token = await getUserToken();
  if (!token) return [];
  const response = await lambdaAxios.get<DocumentObject[]>(
    `${HUBSPOT_DOCUMENTS_URL}/${dealId}`,
    generateHeaders(token),
  );

  return response.data;
};

export const updateDocumentProperties = async (
  documentObjectIds: string[],
  properties: Partial<DocumentObjectProperties>,
) => {
  const token = await getUserToken();
  if (!token) return;
  if (documentObjectIds.length === 0) return;

  const url = `${HUBSPOT_DOCUMENTS_URL}?hubspotObjectId=${documentObjectIds.join(
    '&hubspotObjectId=',
  )}`;

  await lambdaAxios.post(url, properties, generateHeaders(token));
};

export const getERCADocumentsProperties = async (dealId: string) => {
  const token = await getUserToken();
  if (!token) return [];
  const response = await lambdaAxios.get<ERCADocumentObject[]>(
    `${HUBSPOT_ERCA_URL}/${dealId}`,
    generateHeaders(token),
  );

  return response.data;
};

export const updateERCADocument = async (
  dealId: string,
  documentId: string,
  properties: Partial<ERCADocumentObject>,
) => {
  const token = await getUserToken();
  if (!token) return;

  const url = `${HUBSPOT_ERCA_URL}?dealId=${dealId}&documentId=${documentId}`;

  await lambdaAxios.post(url, properties, generateHeaders(token));
};

export const uploadCustomDocument = async (
  file: File,
  displayName: string,
  dealId: string,
) => {
  const token = await getUserToken();
  if (!token) return;

  const fileName = await uploadFile(dealId, 'documents', file);

  await lambdaAxios.post(
    `/hubspot/document/create/${dealId}`,
    {
      s3FileName: fileName,
      fileNamingConvention: displayName,
    },
    generateHeaders(token),
  );
};

export const getInvalidReasons = async () => {
  const token = await getUserToken();
  if (!token) return undefined;

  const response = await lambdaAxios.get<InvalidReason[]>(
    `${HUBSPOT_INVALIDREASONS_URL}`,
    generateHeaders(token),
  );
  return response.data;
};
