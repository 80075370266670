import { Box, Button, Modal, Typography } from '@mui/material';
import {
  Dispatch,
  FunctionComponent,
  SetStateAction,
  SyntheticEvent,
} from 'react';

interface IProps {
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  onConfirm: () => void;
}

export const DeleteCreditModal: FunctionComponent<IProps> = ({
  isOpen,
  setIsOpen,
  onConfirm,
}) => {
  const handleClose = (_event: SyntheticEvent, reason: string) => {
    if (reason && reason === 'backdropClick') return;
    setIsOpen(false);
  };

  return (
    <Modal
      open={isOpen}
      onClose={handleClose}
      aria-labelledby="delete-credit-object-modal-title"
      aria-describedby="delete-credit-object-modal-description"
    >
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: 400,
          bgcolor: 'background.paper',
          boxShadow: 24,
          borderRadius: 1,
          py: 3,
          px: 2,
        }}
      >
        <Typography
          id="delete-credit-object-modal-title"
          textAlign="center"
          fontSize="18px"
        >
          Unchecking this will delete the file and qualifying credits for this
          form. Do you wish to continue?
        </Typography>
        <Box sx={{ marginTop: 2, marginBotton: 1, display: 'flex' }}>
          <Button
            sx={{ marginRight: 0.5, flexGrow: 1, flexBasis: 0 }}
            variant="contained"
            color="error"
            onClick={() => setIsOpen(false)}
          >
            Cancel
          </Button>
          <Button
            sx={{ marginRight: 0.5, flexGrow: 1, flexBasis: 0 }}
            variant="outlined"
            color="success"
            onClick={onConfirm}
          >
            Yes
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};
