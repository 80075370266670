const MINUTES_PER_HOUR = 60;
const MILLISECONDS_PER_MINUTE = 60000;

export const CACHE_TIME = 12 * MINUTES_PER_HOUR * MILLISECONDS_PER_MINUTE;
export const STALE_TIME = 12 * MINUTES_PER_HOUR * MILLISECONDS_PER_MINUTE;

export const IDLE_TIMEOUT = MILLISECONDS_PER_MINUTE * 30;

export const ERC_YEARS = [2023, 2021, 2020, 2019];

export const ERCA_YEARS = [2021, 2020, 2019];

export const DOCUMENT_STATUSES = {
  NOT_YET_RECEIVED: 'Not Yet Received',
  PENDING_REVIEW: 'Pending IR Review',
  IQ_CONFIRMED: 'IQ Confirmed',
  IR_CONFIRMED: 'Confirmed by IR',
  IQ_QC_CONFIRMED: 'IQ QC Confirmed',
  IR_QC_CONFIRMED: 'QC Confirmed',
  NA: 'N/A',
};

export const CREDIT_OBJECT_TYPES = [
  { displayName: '941x - 2020 Q1', hubspotName: '2020 ERC | Q1' },
  { displayName: '941x - 2020 Q2', hubspotName: '2020 ERC | Q2' },
  { displayName: '941x - 2020 Q3', hubspotName: '2020 ERC | Q3' },
  { displayName: '941x - 2020 Q4', hubspotName: '2020 ERC | Q4' },
  { displayName: '941x - 2021 Q1', hubspotName: '2021 ERC | Q1' },
  { displayName: '941x - 2021 Q2', hubspotName: '2021 ERC | Q2' },
  { displayName: '941x - 2021 Q3', hubspotName: '2021 ERC | Q3' },
  { displayName: '941x - 2021 Q4', hubspotName: '2021 ERC | Q4' },
  { displayName: '943x - 2020', hubspotName: '2020 ERC | 943' },
  { displayName: '943x - 2021', hubspotName: '2021 ERC | 943' },
];
