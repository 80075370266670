import { CACHE_TIME, STALE_TIME } from 'helpers/constants';
import {
  generateDocumentObjectsQueryKey,
  generateERCADocumentObjectsQueryKey,
  generateInvalidReasonsQueryKey,
} from 'dataLayer/queryKeys';
import { errorHandler } from 'helpers/utils';
import {
  getDocumentProperties,
  getERCADocumentsProperties,
  getInvalidReasons,
} from './api';

export const documentObjectsQuery = (dealId: string) => ({
  queryKey: generateDocumentObjectsQueryKey(dealId),
  queryFn: () => getDocumentProperties(dealId),
  cacheTime: CACHE_TIME,
  staleTime: STALE_TIME,
  onError: (error: unknown) => errorHandler(error),
});

export const ercaDocumentObjectsQuery = (dealId: string) => ({
  queryKey: generateERCADocumentObjectsQueryKey(dealId),
  queryFn: () => getERCADocumentsProperties(dealId),
  cacheTime: CACHE_TIME,
  staleTime: STALE_TIME,
  onError: (error: unknown) => errorHandler(error),
});

export const invalidReasonsQuery = () => ({
  queryKey: generateInvalidReasonsQueryKey(),
  queryFn: () => getInvalidReasons(),
  cacheTime: CACHE_TIME,
  staleTime: STALE_TIME,
  onError: (error: unknown) => errorHandler(error),
});
