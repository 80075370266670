import {
  Box,
  FormControl,
  MenuItem,
  Select,
  TableCell,
  Tooltip,
  Typography,
} from '@mui/material';
import { DownloadButton } from 'components/Buttons/DownloadButton';
import { UploadButton } from 'components/Buttons/UploadButton';
import { TableBoldedTypography } from 'components/Typography/TableBoldedTypography';
import { updateERCADocument } from 'dataLayer/Document/api';
import {
  useERCADocumentMutation,
  useERCADocumentMutationForUpload,
} from 'dataLayer/Document/mutations';
import { uploadFile } from 'dataLayer/Files/api';
import { format, parseISO } from 'date-fns';
import { successHandler } from 'helpers/utils';
import { getDocumentTableStyles } from 'pages/Deals/Deal/documentsTableStyles';
import { ChangeEvent, FunctionComponent } from 'react';
import { ERCADocumentObject } from 'types';

const ERCA_DOCUMENT_STATUSES = {
  UPLOAD_REQUIRED: 'Upload Required',
  PENDING_REVIEW: 'Pending Review',
  CONFIRMED: 'Confirmed',
};

interface IProps {
  ercaDocument: ERCADocumentObject;
  dealId: string;
}

export const ERCADocumentTableCells: FunctionComponent<IProps> = ({
  ercaDocument,
  dealId,
}) => {
  const {
    dataStatusColumnStyle,
    dataStatusColumnMenuButtonStyle,
    documentNameColumnStyle,
    statusColumnStyle,
    uploadDateColumnStyle,
  } = getDocumentTableStyles('customObjectStyle');

  const statusOptions = [
    {
      displayName: '1 - Upload Required',
      hubspotName: ERCA_DOCUMENT_STATUSES.UPLOAD_REQUIRED,
    },
    {
      displayName: '2 - Pending Review',
      hubspotName: ERCA_DOCUMENT_STATUSES.PENDING_REVIEW,
    },
    {
      displayName: '3 - Confirmed',
      hubspotName: ERCA_DOCUMENT_STATUSES.CONFIRMED,
    },
  ];

  const getStatusColor = (status: string | undefined) => {
    switch (status) {
      case ERCA_DOCUMENT_STATUSES.UPLOAD_REQUIRED:
        return 'red';
      case ERCA_DOCUMENT_STATUSES.PENDING_REVIEW:
        return 'orange';
      case ERCA_DOCUMENT_STATUSES.CONFIRMED:
        return 'green';
      default:
        return 'gray';
    }
  };

  const updateERCADocumentObjectHandler = async (documentInfo: {
    documentId: string;
    documentProperties: Partial<ERCADocumentObject>;
  }) => {
    await updateERCADocument(
      dealId,
      documentInfo.documentId,
      documentInfo.documentProperties,
    );
  };

  const updateERCADocumentMutation = useERCADocumentMutation(
    updateERCADocumentObjectHandler,
    dealId,
  );

  const lastModifiedDate = ercaDocument.fileLastModifiedDate
    ? format(parseISO(ercaDocument.fileLastModifiedDate), 'P')
    : '';

  const uploadERCAFileHandler = async (
    event: ChangeEvent<HTMLInputElement>,
  ) => {
    const file = event.target.files?.[0];
    if (file) {
      const fileName = await uploadFile(dealId, 'erca', file);
      successHandler('File successfully uploaded!');
      await updateERCADocument(dealId, ercaDocument.documentId, {
        fileName,
      });
    }
  };

  const uploadERCAFileMutation = useERCADocumentMutationForUpload(
    uploadERCAFileHandler,
    dealId,
    ercaDocument,
  );

  return (
    <>
      {/* Colored Status Indicator */}
      <TableCell align="center" sx={statusColumnStyle}>
        <Box
          sx={{
            width: '12px',
            height: '12px',
            backgroundColor: getStatusColor(ercaDocument.status),
            border: `4px solid ${getStatusColor(ercaDocument.status)}`,
            borderRadius: '50%',
            margin: '0 auto',
          }}
        />
      </TableCell>

      {/* File Name and Upload / Download Buttons  */}
      <TableCell sx={documentNameColumnStyle}>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <TableBoldedTypography
            sx={{ maxWidth: '70%', wordWrap: 'break-word' }}
            text={ercaDocument.displayName}
          />
          <UploadButton mutationMethod={uploadERCAFileMutation} />
        </Box>
        {!!ercaDocument.fileName && (
          <DownloadButton
            dealId={dealId}
            fileInformation={{
              fileName: ercaDocument.fileName,
              fileType: 'erca',
            }}
            isUploading={uploadERCAFileMutation.isLoading}
          />
        )}
      </TableCell>

      {/* Document Last Modified Date */}
      <TableCell sx={uploadDateColumnStyle}>
        <Tooltip title={lastModifiedDate} enterDelay={0}>
          <Typography
            sx={{
              textOverflow: 'ellipsis',
              overflow: 'hidden',
            }}
          >
            {lastModifiedDate}
          </Typography>
        </Tooltip>
      </TableCell>

      <TableCell sx={dataStatusColumnStyle}>
        <FormControl sx={dataStatusColumnMenuButtonStyle} size="small">
          <Select
            value={ercaDocument.status}
            onChange={(event) => {
              updateERCADocumentMutation.mutate({
                documentId: ercaDocument.documentId,
                documentProperties: {
                  status: event.target.value,
                },
              });
            }}
            displayEmpty
          >
            {statusOptions.map((statusOption) => (
              <MenuItem
                key={statusOption.hubspotName}
                value={statusOption.hubspotName}
              >
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  <Box
                    sx={{
                      width: '8px',
                      minWidth: '8px',
                      height: '8px',
                      backgroundColor: getStatusColor(statusOption.hubspotName),
                      border: `2px solid ${getStatusColor(
                        statusOption.hubspotName,
                      )}`,
                      borderRadius: '50%',
                      margin: '0 auto',
                    }}
                  />
                  <Typography
                    sx={{
                      textOverflow: 'ellipsis',
                      overflow: 'hidden',
                      paddingLeft: 1,
                      flexGrow: 1,
                    }}
                  >
                    {statusOption.displayName}
                  </Typography>
                </Box>
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </TableCell>
    </>
  );
};
