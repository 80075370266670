import { filesQuery } from 'dataLayer/Files/get';
import { CACHE_TIME, STALE_TIME } from 'helpers/constants';
import {
  generateDealsQueryKey,
  generateDealObjectQueryKey,
  generateBqfDisplayNamesQueryKey,
  generateNotesQueryKey,
} from 'dataLayer/queryKeys';
import { errorHandler } from 'helpers/utils';
import {
  getDealsSummary,
  getDealProperties,
  getBqfDisplayNames,
  getDealCaseRejectionCategories,
  getDealNotes,
} from './api';

export const dealsQuery = (prefix: string) => ({
  queryKey: generateDealsQueryKey(prefix),
  queryFn: () => getDealsSummary(prefix),
  cacheTime: CACHE_TIME,
  staleTime: STALE_TIME,
  onError: (error: unknown) => errorHandler(error),
});

export const dealPropertiesQuery = (dealId: string) => ({
  queryKey: generateDealObjectQueryKey(dealId),
  queryFn: () => getDealProperties(dealId),
  cacheTime: CACHE_TIME,
  staleTime: STALE_TIME,
  onError: (error: unknown) => errorHandler(error),
});

export const form2848FilesQuery = (dealId: string) =>
  filesQuery(dealId, '2848');

export const form8821FilesQuery = (dealId: string) =>
  filesQuery(dealId, '8821');

export const taxEngagementFilesQuery = (dealId: string) =>
  filesQuery(dealId, 'taxEngagement');

export const statementOfFactsFilesQuery = (dealId: string) =>
  filesQuery(dealId, 'statementOfFacts');

export const bqfDisplayNamesQuery = () => ({
  queryKey: generateBqfDisplayNamesQueryKey(),
  queryFn: () => getBqfDisplayNames(),
  cacheTime: CACHE_TIME,
  staleTime: STALE_TIME,
  onError: (error: unknown) => errorHandler(error),
});

export const caseRejectionCategoriesQuery = (dealId: string) => ({
  queryKey: ['case_rejection_categories', dealId],
  queryFn: () => getDealCaseRejectionCategories(dealId),
  cacheTime: CACHE_TIME,
  staleTime: STALE_TIME,
  onError: (error: unknown) => errorHandler(error),
});

export const notesQuery = (dealId: string) => ({
  queryKey: generateNotesQueryKey(dealId),
  queryFn: () => getDealNotes(dealId),
  cacheTime: CACHE_TIME,
  staleTime: STALE_TIME,
  onError: (error: unknown) => errorHandler(error),
});
