import { getUserToken } from 'dataLayer/Cognito';
import { lambdaAxios, generateHeaders } from 'helpers/utils';
import {
  DealNoteObject,
  DealNoteProperties,
  DealObject,
  DealObjectProperties,
  EnumProperty,
  GetDealsSummaryResponse,
} from 'types';

const GET_DEALS_URL = '/deals';
const HUBSPOT_DEALS_URL = '/hubspot/deals';
const HUBSPOT_BQF_DISPLAY_NAMES_URL = '/hubspot/bqfdisplaynames';
const HUBSPOT_NOTES_URL = '/hubspot/notes';
const LENDER_SUBMIT_URL = '/lender/submit-deal';

export const getDealsSummary = async (prefix: string) => {
  const token = await getUserToken();
  if (!token) return [];

  const response = await lambdaAxios.get<GetDealsSummaryResponse>(
    `${GET_DEALS_URL}?prefix=${prefix}`,
    generateHeaders(token),
  );
  return response.data.deals;
};

export const getDealProperties = async (dealId: string) => {
  const token = await getUserToken();
  if (!token) return undefined;

  const response = await lambdaAxios.get<DealObject>(
    `${HUBSPOT_DEALS_URL}/${dealId}`,
    generateHeaders(token),
  );
  return response.data;
};

export const getDealCaseRejectionCategories = async (dealId: string) => {
  const token = await getUserToken();
  if (!token) return undefined;

  const { data: categories } = await lambdaAxios.get<EnumProperty[]>(
    `${HUBSPOT_DEALS_URL}/${dealId}/caseRejectionCategories`,
    generateHeaders(token),
  );

  return categories;
};

export const updateDealProperties = async (
  dealIds: string[],
  properties: Partial<DealObjectProperties>,
) => {
  const token = await getUserToken();
  if (!token) return;
  if (dealIds.length === 0) return;

  const url = `${HUBSPOT_DEALS_URL}?hubspotObjectId=${dealIds.join(
    '&hubspotObjectId=',
  )}`;

  await lambdaAxios.post(url, properties, generateHeaders(token));
};

export const submitDealToLender = async (dealId: string) => {
  const token = await getUserToken();
  if (!token) return null;

  const response = await lambdaAxios.post<{ url: string }>(
    `${LENDER_SUBMIT_URL}/${dealId}`,
    undefined,
    generateHeaders(token),
  );
  return response.data;
};

export const getBqfDisplayNames = async () => {
  const token = await getUserToken();
  if (!token) return undefined;

  const response = await lambdaAxios.get<DealObject>(
    `${HUBSPOT_BQF_DISPLAY_NAMES_URL}`,
    generateHeaders(token),
  );
  return response.data;
};

export const createDealNoteObject = async (
  dealId: string,
  dealNoteProperties: Partial<DealNoteProperties>,
) => {
  const token = await getUserToken();
  if (!token) return;

  await lambdaAxios.post(
    `${HUBSPOT_NOTES_URL}/${dealId}`,
    dealNoteProperties,
    generateHeaders(token),
  );
};

export const getDealNotes = async (dealId: string) => {
  const token = await getUserToken();
  if (!token) return [];

  const response = await lambdaAxios.get<DealNoteObject[]>(
    `${HUBSPOT_NOTES_URL}/${dealId}`,
    generateHeaders(token),
  );
  return response.data;
};
