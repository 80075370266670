import { Auth } from 'aws-amplify';
import {
  COGNITO_IS_AUTHENTICATED_QUERY_KEY,
  COGNITO_CURRENT_USER_QUERY_KEY,
  COGNITO_QUERY_KEY,
} from 'dataLayer/queryKeys';
import { useQuery, useQueryClient } from 'react-query';

export const useIsAuthenticated = () => {
  const { data: isAuthenticated, isLoading } = useQuery(
    COGNITO_IS_AUTHENTICATED_QUERY_KEY,
    async () => {
      try {
        await Auth.currentAuthenticatedUser();
        return true;
      } catch {
        return false;
      }
    },
  );

  return { isLoading, isAuthenticated };
};

export const getUserToken = async (): Promise<string | undefined> => {
  const data = await Auth.currentSession();
  return data.getIdToken().getJwtToken();
};

export const useUserGroups = (): string[] => {
  const { data } = useQuery(COGNITO_CURRENT_USER_QUERY_KEY, async () =>
    Auth.currentAuthenticatedUser(),
  );

  return data?.signInUserSession?.idToken?.payload?.['cognito:groups'] ?? [];
};

export const useUserEmail = (): string => {
  const { data } = useQuery(COGNITO_CURRENT_USER_QUERY_KEY, async () =>
    Auth.currentAuthenticatedUser(),
  );

  return data?.attributes?.email ?? '';
};

export const useLogout = (): { logout: () => void } => {
  const qc = useQueryClient();

  const logout = async () => {
    try {
      await Auth.signOut();
      window.history.replaceState({ ...window.history.state, idx: 0 }, '');
      qc.invalidateQueries(COGNITO_QUERY_KEY);
      qc.removeQueries(COGNITO_QUERY_KEY);
      qc.removeQueries(['data']);
    } catch (error) {
      console.info(error);
    }
  };

  return { logout };
};
