import {
  Box,
  Button,
  CircularProgress,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { useAsync } from '@react-hookz/web';
import { DownloadButton } from 'components/Buttons/DownloadButton';
import { RejectCaseModal } from 'components/Modals/RejectCaseModal';
import { submitDealToLender, updateDealProperties } from 'dataLayer/Deal/api';
import { dealPropertiesQuery } from 'dataLayer/Deal/get';
import { useDealObjectMutation } from 'dataLayer/Deal/mutations';
import { getZippedFiles } from 'dataLayer/Files/api';
import { filesQuery } from 'dataLayer/Files/get';
import { errorHandler } from 'helpers/utils';
import moment, { Moment } from 'moment';
import { FunctionComponent, useEffect, useMemo, useState } from 'react';
import { UseMutationResult, useQuery } from 'react-query';
import {
  generatePath,
  Link as RouterLink,
  useLocation,
} from 'react-router-dom';
import { PATHS } from 'Router';
import { DealObjectProperties } from 'types';

interface IRejectCaseProps {
  rejectCaseDate: string | undefined;
  dealId: string;
}

const RejectCase: FunctionComponent<IRejectCaseProps> = ({
  rejectCaseDate,
  dealId,
}) => {
  const { data: files } = useQuery(filesQuery(dealId, 'taxDocuments'));

  const caseRejectionReportFile = useMemo(() => {
    const caseRejectionReportFiles =
      files?.filter((file) =>
        file.name.includes(`CaseRejectionReport - ${dealId}`),
      ) ?? [];
    if (caseRejectionReportFiles.length) {
      caseRejectionReportFiles.sort((file1, file2) => {
        const modifiedDateFile1 = new Date(file1.lastModified);
        const modifiedDateFile2 = new Date(file2.lastModified);

        return modifiedDateFile1.valueOf() - modifiedDateFile2.valueOf();
      });
      return caseRejectionReportFiles.pop();
    }
    return undefined;
  }, [files, dealId]);

  return rejectCaseDate ? (
    <Box sx={{ display: 'flex', flexDirection: 'column', marginLeft: 'auto' }}>
      <Typography
        fontSize="16px"
        color="portalPalettes.darkgray"
        fontWeight="700"
      >
        File Rejected Date
      </Typography>
      <Typography
        fontSize="16px"
        color="portalPalettes.darkgray"
        fontWeight="700"
      >
        {
          new Date(rejectCaseDate)
            .toLocaleString('en-US', { timeZone: 'UTC' })
            .split(',')[0]
        }
      </Typography>
      {!!caseRejectionReportFile && (
        <DownloadButton
          dealId={dealId}
          fileInformation={{
            fileName: caseRejectionReportFile.name,
            fileType: 'taxDocuments',
          }}
        />
      )}
    </Box>
  ) : (
    <RejectCaseModal dealId={dealId} />
  );
};

interface ICallSchedulerProps {
  callRequested?: boolean;
  updateDealObjectMutation: UseMutationResult<
    void,
    unknown,
    Partial<DealObjectProperties>
  >;
}

const CallScheduler: FunctionComponent<ICallSchedulerProps> = ({
  callRequested,
  updateDealObjectMutation,
}) => (
  <>
    {!callRequested ? (
      <Button
        variant="outlined"
        onClick={() => {
          updateDealObjectMutation.mutate({
            callRequestedByTaxPreparer: 'True',
          });
        }}
        sx={{
          marginRight: 2,
          color: 'portalPalettes.green',
          borderColor: 'portalPalettes.green',
          fontSize: '16px',
        }}
      >
        Schedule Customer Call
      </Button>
    ) : (
      <Button
        disabled
        sx={{
          marginRight: 2,
          color: 'portalPalettes.green',
          borderColor: 'portalPalettes.green',
          fontSize: '16px',
        }}
      >
        Request Confirmed
        <CheckCircleIcon />
      </Button>
    )}
  </>
);

interface IProps {
  dealId: string;
}

export const DealHeader: FunctionComponent<IProps> = ({ dealId }) => {
  const { pathname: currentPath } = useLocation();

  const { data: dealProperties, isLoading: isDealPropertiesLoading } = useQuery(
    dealPropertiesQuery(dealId),
  );

  const initializeDate = (formattedDate: string) =>
    new Date(
      new Date(formattedDate).valueOf() +
        60000 * new Date().getTimezoneOffset(), // 60000 is the number of milliseconds in a minute, which is the unit of the timezone offset
    );

  const formatDate = (date: Moment | null) => {
    if (!date) return undefined;
    return date.format('YYYY-MM-DD');
  };

  const cpaCompletionDate = useMemo(
    () =>
      dealProperties?.properties.estimatedCPACompletionDate
        ? initializeDate(dealProperties?.properties.estimatedCPACompletionDate)
        : null,
    [dealProperties?.properties.estimatedCPACompletionDate],
  );

  const callRequested = useMemo(
    () => dealProperties?.properties.callRequestedByTaxPreparer,
    [dealProperties],
  );

  const isUsingPEO = useMemo(
    () =>
      dealProperties?.properties
        .atAnyPointInTimeDuring2020Or2021DidYouUtilizeAPeoProfessionalEmployerOrganization ??
      '',
    [
      dealProperties?.properties
        .atAnyPointInTimeDuring2020Or2021DidYouUtilizeAPeoProfessionalEmployerOrganization,
    ],
  );

  const [completionDate, setCompletionDate] = useState<Moment | null>(
    moment(cpaCompletionDate),
  );

  const [PEOUtilization, setPEOUtilization] = useState<string>(isUsingPEO);

  useEffect(() => {
    if (cpaCompletionDate) setCompletionDate(moment(cpaCompletionDate));
  }, [cpaCompletionDate]);

  useEffect(() => {
    if (isUsingPEO) setPEOUtilization(isUsingPEO);
  }, [isUsingPEO]);

  const updateDealObjectHandler = async (
    newDealProperties: Partial<DealObjectProperties>,
  ) => {
    await updateDealProperties([dealId], newDealProperties);
  };

  const updateDealObjectMutation = useDealObjectMutation(
    updateDealObjectHandler,
    dealId,
  );

  const [downloadZipState, downloadZipAction] = useAsync(async () => {
    try {
      const file = await getZippedFiles(dealId, 'documents');
      if (file) window.open(file.url, '_blank');
    } catch (error) {
      errorHandler(error);
    }
  });

  const [downloadERCAZipState, downloadERCAZipAction] = useAsync(async () => {
    try {
      const file = await getZippedFiles(dealId, 'erca');
      if (file) window.open(file.url, '_blank');
    } catch (error) {
      errorHandler(error);
    }
  });

  const [submitERCAState, submitERCAAction] = useAsync(async () => {
    try {
      const response = await submitDealToLender(dealId);
      console.info(response);
    } catch (error) {
      errorHandler(error);
    }
  });

  const getPageName = () => {
    if (currentPath.includes('cpa')) {
      return 'Manage Case';
    }
    if (currentPath.includes('documents')) {
      return 'Manage Documents';
    }
    if (currentPath.includes('erca')) {
      return 'Manage ERCA';
    }
    return 'Refund Application';
  };

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          marginTop: 2,
          '& hr': {
            mx: 1,
          },
        }}
      >
        <Typography sx={{ marginRight: 1 }} fontSize="24px" color="#24205A">
          {getPageName()}
        </Typography>

        <Typography
          sx={{ marginX: 1, marginTop: 1 }}
          fontSize="16px"
          color="#5F61AB"
        >
          {dealProperties?.properties.companyName
            ? `${dealProperties.properties.companyName} | `
            : ''}
          Application ID: {dealId}
        </Typography>
      </Box>
      <Box
        sx={{
          display: 'flex',
          marginY: 2,
          '& hr': {
            mx: 1,
          },
        }}
      >
        {!currentPath.includes('overview') &&
          !currentPath.includes('file-requests') && (
            <>
              <Button
                variant="outlined"
                component={RouterLink}
                to={generatePath(PATHS.BQF, { dealId })}
                sx={{
                  marginRight: 2,
                  color: 'portalPalettes.green',
                  borderColor: 'portalPalettes.green',
                  fontSize: '16px',
                }}
              >
                Company Information
              </Button>
              <Button
                disabled={downloadZipState.status === 'loading'}
                variant="outlined"
                sx={{
                  marginRight: 2,
                  color: 'portalPalettes.green',
                  borderColor: 'portalPalettes.green',
                  fontSize: '16px',
                }}
                onClick={downloadZipAction.execute}
              >
                {downloadZipState.status === 'loading' ? (
                  <CircularProgress size={20} />
                ) : (
                  'Download Case Files'
                )}
              </Button>
              {currentPath.includes('erca') && (
                <Button
                  disabled={downloadERCAZipState.status === 'loading'}
                  variant="outlined"
                  sx={{
                    marginRight: 2,
                    color: 'portalPalettes.green',
                    borderColor: 'portalPalettes.green',
                    fontSize: '16px',
                  }}
                  onClick={downloadERCAZipAction.execute}
                >
                  {downloadERCAZipState.status === 'loading' ? (
                    <CircularProgress size={20} />
                  ) : (
                    'Download ERCA Files'
                  )}
                </Button>
              )}
              <Button
                variant="outlined"
                component={RouterLink}
                to={generatePath(PATHS.FILE_REQUESTS, { dealId })}
                sx={{
                  marginRight: 2,
                  color: 'portalPalettes.green',
                  borderColor: 'portalPalettes.green',
                  fontSize: '16px',
                }}
              >
                File Requests
              </Button>
              {currentPath.includes('erca') && (
                <Button
                  disabled={submitERCAState.status === 'loading'}
                  variant="outlined"
                  sx={{
                    marginRight: 2,
                    color: 'portalPalettes.green',
                    borderColor: 'portalPalettes.green',
                    fontSize: '16px',
                  }}
                  onClick={submitERCAAction.execute}
                >
                  {submitERCAState.status === 'loading' ? (
                    <CircularProgress size={20} />
                  ) : (
                    'Submit Deal to Lender'
                  )}
                </Button>
              )}
            </>
          )}
        {currentPath.includes('cpa') && (
          <Box
            sx={{
              display: 'flex',
              flexGrow: 1,
            }}
          >
            <LocalizationProvider dateAdapter={AdapterMoment}>
              <DatePicker
                label="CPA Completion Date"
                value={completionDate?.isValid() ? completionDate : null}
                onChange={(newValue) => {
                  updateDealObjectMutation.mutate({
                    estimatedCPACompletionDate: formatDate(newValue),
                  });
                  setCompletionDate(newValue);
                }}
                renderInput={(params) => (
                  <TextField
                    sx={{ marginRight: 2, width: '210px' }}
                    {...params}
                  />
                )}
              />
            </LocalizationProvider>
            <FormControl sx={{ minWidth: 150, marginRight: 2 }}>
              <InputLabel>PEO Utilization</InputLabel>
              <Select
                label="PEO Utilization"
                fullWidth
                value={PEOUtilization}
                onChange={(event) => {
                  updateDealObjectMutation.mutate({
                    atAnyPointInTimeDuring2020Or2021DidYouUtilizeAPeoProfessionalEmployerOrganization:
                      event?.target.value,
                  });
                  setPEOUtilization(event.target.value);
                }}
              >
                <MenuItem value="Yes">Yes</MenuItem>
                <MenuItem value="No">No</MenuItem>
              </Select>
            </FormControl>

            {!isDealPropertiesLoading ? (
              <>
                <CallScheduler
                  callRequested={Boolean(callRequested)}
                  updateDealObjectMutation={updateDealObjectMutation}
                />
                <RejectCase
                  rejectCaseDate={
                    dealProperties?.properties.taxAttorneyRejectedDate
                  }
                  dealId={dealId}
                />
              </>
            ) : null}
          </Box>
        )}
      </Box>
    </>
  );
};
