import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { UploadButton } from 'components/Buttons/UploadButton';
import { LoadingIndicator } from 'components/LoadingIndicator/LoadingIndicator';
import { TableHeaderTypography } from 'components/Typography/TableHeaderTypography';
import { updateDealProperties } from 'dataLayer/Deal/api';
import { uploadCPAFile, uploadFile } from 'dataLayer/Files/api';
import { filesQuery } from 'dataLayer/Files/get';
import { useS3FileUploadMutation } from 'dataLayer/Files/mutations';
import { errorHandler, successHandler } from 'helpers/utils';
import { getDocumentTableStyles } from 'pages/Deals/Deal/documentsTableStyles';
import { ChangeEvent, FunctionComponent } from 'react';
import { useQuery } from 'react-query';
import { CSCFileTableCells } from './TableCells/CSCFileTableCells';

interface IProps {
  dealId: string;
}

export const S3FilesTable: FunctionComponent<IProps> = ({ dealId }) => {
  const { data: files, isLoading } = useQuery(filesQuery(dealId, 'documents'));

  const uploadFileHandler = async (event: ChangeEvent<HTMLInputElement>) => {
    const promises = [];
    const numberOfFiles = event.target.files?.length ?? 0;
    for (let i = 0; i < numberOfFiles; i += 1) {
      const file = event.target.files?.item(i);
      if (file) {
        promises.push(uploadFile(dealId, 'documents', file));
      }
    }
    await Promise.all(promises);
    successHandler(
      `${numberOfFiles > 1 ? 'Files' : 'File'} successfully uploaded!`,
    );
  };

  const uploadDASheetHandler = async (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      await uploadCPAFile(dealId, file, 'documents', 'Data Agg Sheet');
      await updateDealProperties([dealId], {
        aggregationCompleteMarker: 'Yes',
      });
      successHandler('Data Aggregation Sheet successfully uploaded!');
    } else {
      errorHandler('File failed to upload');
    }
  };

  const uploadFileMutation = useS3FileUploadMutation(
    uploadFileHandler,
    dealId,
    'documents',
  );
  const uploadDASheetMutation = useS3FileUploadMutation(
    uploadDASheetHandler,
    dealId,
    'documents',
  );

  const {
    documentNameColumnStyle,
    dataAssociationColumnStyle,
    uploadDateColumnStyle,
  } = getDocumentTableStyles();

  return (
    <>
      <Typography
        fontSize="25px"
        fontWeight="800"
        marginTop={4}
        marginBottom={2}
      >
        All Files
        <UploadButton
          mutationMethod={uploadFileMutation}
          allowMultipleUploads
        />
        <UploadButton mutationMethod={uploadDASheetMutation} isDASheetUpload />
      </Typography>
      {isLoading ? (
        <LoadingIndicator />
      ) : (
        <TableContainer>
          <Table>
            <TableHead
              sx={{
                height: '75px',
              }}
            >
              <TableRow>
                <TableCell sx={documentNameColumnStyle}>
                  <TableHeaderTypography text="File Name" />
                </TableCell>
                <TableCell sx={uploadDateColumnStyle}>
                  <TableHeaderTypography text="Uploaded" />
                </TableCell>
                <TableCell sx={dataAssociationColumnStyle}>
                  <TableHeaderTypography text="Data Association" />
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {files?.map((file) => (
                <TableRow
                  key={file.name}
                  sx={{
                    boxShadow: '0px 1px 0px lightgray',
                  }}
                >
                  <CSCFileTableCells file={file} dealId={dealId} />
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </>
  );
};
