import {
  Box,
  Button,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { useAsync } from '@react-hookz/web';
import { DownloadButton } from 'components/Buttons/DownloadButton';
import { DealHeader } from 'components/Header/DealHeader';
import { Header } from 'components/Header/Header';
import { LoadingIndicator } from 'components/LoadingIndicator/LoadingIndicator';
import { FileRequestModal } from 'components/Modals/FileRequestModal';
import { TableHeaderTypography } from 'components/Typography/TableHeaderTypography';
import { useUserGroups } from 'dataLayer/Cognito';
import { getZippedFiles } from 'dataLayer/Files/api';
import { filesQuery } from 'dataLayer/Files/get';
import { updateDealTicketObjects } from 'dataLayer/Tickets/api';
import { ticketsQuery } from 'dataLayer/Tickets/get';
import { useUpdateDealTicketObjectMutation } from 'dataLayer/Tickets/mutations';
import { format, parseISO } from 'date-fns';
import { errorHandler } from 'helpers/utils';
import { FunctionComponent } from 'react';
import { useQuery } from 'react-query';
import { RouteProps, useParams } from 'react-router';
import { DealTicketProperties } from 'types';

interface IProps {
  dealId: string;
}

const FileRequestsTable: FunctionComponent<IProps> = ({ dealId }) => {
  const {
    data: filesForFileRequests,
    isLoading: isLoadingFilesForFileRequests,
  } = useQuery(filesQuery(dealId, 'fileRequests'));

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          marginTop: 4,
          marginBottom: 2,
        }}
      >
        <Typography
          fontSize="20px"
          color="portalPalettes.darkgray"
          fontWeight="700"
        >
          File Request Files
        </Typography>
      </Box>
      {isLoadingFilesForFileRequests ? (
        <LoadingIndicator />
      ) : (
        <TableContainer
          sx={{
            marginTop: 4,
          }}
        >
          <Table>
            <TableHead
              sx={{
                height: '75px',
              }}
            >
              <TableRow>
                <TableCell>
                  <TableHeaderTypography text="File Name" />
                </TableCell>
                <TableCell>
                  <TableHeaderTypography text="Upload Date" />
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {filesForFileRequests?.map((fileObject) => (
                <TableRow key={fileObject.name}>
                  <TableCell>
                    <DownloadButton
                      dealId={dealId}
                      fileInformation={{
                        fileName: fileObject.name,
                        fileType: 'fileRequests',
                      }}
                    />
                  </TableCell>
                  <TableCell>
                    {fileObject.lastModified
                      ? format(parseISO(fileObject.lastModified), 'P')
                      : ''}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </>
  );
};

const HubspotTicketsTable: FunctionComponent<IProps> = ({ dealId }) => {
  const { data: tickets, isLoading: isLoadingTickets } = useQuery(
    ticketsQuery(dealId),
  );

  const updateTicketObjectHandler = async (ticketInfo: {
    ticketId: string;
    dealTicketProperties: Partial<DealTicketProperties>;
  }) => {
    await updateDealTicketObjects(
      [ticketInfo.ticketId],
      ticketInfo.dealTicketProperties,
    );
  };

  const updateTicketObjectMutator = useUpdateDealTicketObjectMutation(
    updateTicketObjectHandler,
    dealId,
  );

  return (
    <Box
      sx={{
        marginBottom: 10,
      }}
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          marginTop: 4,
          marginBottom: 2,
        }}
      >
        <Typography
          fontSize="20px"
          color="portalPalettes.darkgray"
          fontWeight="700"
        >
          File Request History
        </Typography>
      </Box>
      {isLoadingTickets ? (
        <LoadingIndicator />
      ) : (
        <TableContainer
          sx={{
            marginTop: 4,
          }}
        >
          <Table>
            <TableHead
              sx={{
                height: '75px',
              }}
            >
              <TableRow>
                <TableCell>
                  <TableHeaderTypography text="Document Type" />
                </TableCell>
                <TableCell>
                  <TableHeaderTypography text="Notes" />
                </TableCell>
                <TableCell>
                  <TableHeaderTypography text="Created Date" />
                </TableCell>
                <TableCell>
                  <TableHeaderTypography text="Status" />
                </TableCell>
                <TableCell>
                  <TableHeaderTypography text="Update Request" />
                </TableCell>
                <TableCell>
                  <TableHeaderTypography text="Last Status Update" />
                </TableCell>
                <TableCell>
                  <TableHeaderTypography text="CSC Status Notes" />
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {tickets?.map((ticket) => (
                <TableRow key={ticket.id}>
                  <TableCell>{ticket.properties.documentType}</TableCell>
                  <TableCell>{ticket.properties.notes}</TableCell>
                  <TableCell>
                    {ticket.properties.createDate &&
                      format(parseISO(ticket.properties.createDate), 'P')}
                  </TableCell>
                  <TableCell>{ticket.properties.status}</TableCell>
                  <TableCell>
                    {ticket.properties.updateRequested === 'true' ? (
                      'Update Requested'
                    ) : (
                      <Button
                        variant="contained"
                        sx={{ backgroundColor: 'portalPalettes.green' }}
                        onClick={() =>
                          updateTicketObjectMutator.mutate({
                            ticketId: ticket.id,
                            dealTicketProperties: {
                              updateRequested: 'true',
                            },
                          })
                        }
                      >
                        Request Status Update
                      </Button>
                    )}
                  </TableCell>
                  <TableCell>
                    {ticket.properties.lastStatusUpdate &&
                      format(parseISO(ticket.properties.lastStatusUpdate), 'P')}
                  </TableCell>
                  <TableCell>{ticket.properties.cscStatusNotes}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </Box>
  );
};

export const FileRequests: FunctionComponent<RouteProps> = () => {
  const { dealId } = useParams() as { dealId: string };
  const userGroups = useUserGroups();

  const [downloadZipState, downloadZipAction] = useAsync(async () => {
    try {
      const file = await getZippedFiles(dealId, 'fileRequests');
      if (file) window.open(file.url, '_blank');
    } catch (error) {
      errorHandler(error);
    }
  });

  return (
    <>
      <Header />
      <DealHeader dealId={dealId} />
      {userGroups.includes('cpa') && <FileRequestModal dealId={dealId} />}
      <Button
        disabled={downloadZipState.status === 'loading'}
        variant="outlined"
        sx={{
          color: 'portalPalettes.green',
          borderColor: 'portalPalettes.green',
          fontSize: '18px',
        }}
        onClick={downloadZipAction.execute}
      >
        {downloadZipState.status === 'loading' ? (
          <CircularProgress size={20} />
        ) : (
          'Download All Files'
        )}
      </Button>
      <FileRequestsTable dealId={dealId} />
      <HubspotTicketsTable dealId={dealId} />
    </>
  );
};
