import {
  Box,
  FormControl,
  MenuItem,
  Select,
  TableCell,
  Tooltip,
  Typography,
} from '@mui/material';
import { DownloadButton } from 'components/Buttons/DownloadButton';
import { UploadButton } from 'components/Buttons/UploadButton';
import { DataAssociationsDropdownMenu } from 'components/DropdownMenus/AssociationsDropdownMenu';
import { RotateDropdownMenu } from 'components/DropdownMenus/RotateDropdownMenu';
import { EditDocumentModal } from 'components/Modals/EditDocumentModal';
import { TableBoldedTypography } from 'components/Typography/TableBoldedTypography';
import {
  documentObjectsQuery,
  invalidReasonsQuery,
} from 'dataLayer/Document/get';
import {
  useDocumentObjectMutation,
  useDocumentObjectMutationForUploadProperties,
} from 'dataLayer/Document/mutations';
import { format, parseISO } from 'date-fns';
import {
  successHandler,
  allowedContentTypes,
  filterAssociatedDocumentObjects,
} from 'helpers/utils';
import { DOCUMENT_STATUSES } from 'helpers/constants';
import { getDocumentTableStyles } from 'pages/Deals/Deal/documentsTableStyles';
import {
  ChangeEvent,
  FunctionComponent,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useQuery } from 'react-query';
import { DocumentObject, DocumentObjectProperties } from 'types';
import { updateDocumentProperties } from 'dataLayer/Document/api';
import { uploadFile } from 'dataLayer/Files/api';

const statusOptions = [
  {
    displayName: '1 - Upload required',
    hubspotName: DOCUMENT_STATUSES.NOT_YET_RECEIVED,
  },
  {
    displayName: '2 - Pending review',
    hubspotName: DOCUMENT_STATUSES.PENDING_REVIEW,
  },
  {
    displayName: '3 - IQ CSC confirmed',
    hubspotName: DOCUMENT_STATUSES.IQ_CONFIRMED,
  },
  {
    displayName: '4 - IR CSC confirmed',
    hubspotName: DOCUMENT_STATUSES.IR_CONFIRMED,
  },
  {
    displayName: '5 - IQ QC confirmed',
    hubspotName: DOCUMENT_STATUSES.IQ_QC_CONFIRMED,
  },
  {
    displayName: '6 - IR QC confirmed',
    hubspotName: DOCUMENT_STATUSES.IR_QC_CONFIRMED,
  },
  {
    displayName: '7 - N/A',
    hubspotName: DOCUMENT_STATUSES.NA,
  },
];

const getStatusColor = (status: string) => {
  switch (status) {
    case DOCUMENT_STATUSES.NOT_YET_RECEIVED:
      return 'red';
    case DOCUMENT_STATUSES.PENDING_REVIEW:
      return 'orange';
    case DOCUMENT_STATUSES.IQ_CONFIRMED:
    case DOCUMENT_STATUSES.IR_CONFIRMED:
    case DOCUMENT_STATUSES.IQ_QC_CONFIRMED:
    case DOCUMENT_STATUSES.IR_QC_CONFIRMED:
      return 'green';
    case DOCUMENT_STATUSES.NA:
      return 'gray';
    default:
      return 'gray';
  }
};

const documentIsACheck = (displayName: string) => {
  const regexForCheckFilename = /^\d{2}-\d{4}-check$/i;
  return regexForCheckFilename.test(displayName);
};

interface IProps {
  documentObject: DocumentObject;
  dealId: string;
  isCustomObject?: boolean;
  isReadOnly?: boolean;
}

export const DocumentTableCells: FunctionComponent<IProps> = ({
  documentObject,
  dealId,
  isCustomObject,
  isReadOnly,
}) => {
  const { data: documentObjects, isLoading } = useQuery(
    documentObjectsQuery(dealId),
  );

  const {
    dataAssociationColumnStyle,
    dataStatusColumnStyle,
    dataStatusColumnMenuButtonStyle,
    documentNameColumnStyle,
    invalidReasonsColumnStyle,
    invalidReasonsColumnMenuButtonStyle,
    noteColumnStyle,
    rotateFileColumnStyle,
    statusColumnStyle,
    uploadDateColumnStyle,
  } = getDocumentTableStyles('customObjectStyle');

  const customObjectAssociations = useMemo(
    () =>
      isCustomObject
        ? filterAssociatedDocumentObjects(
            documentObjects,
            documentObject.properties.s3FileName,
          )
        : undefined,
    [documentObjects, isCustomObject, documentObject.properties.s3FileName],
  );

  const { data: invalidReasons, isLoading: isLoadingInvalidReasonOptions } =
    useQuery(invalidReasonsQuery());

  const [selectedDocuments, setSelectedDocuments] = useState<DocumentObject[]>(
    customObjectAssociations ?? [],
  );

  const updateDocumentObjectHandler = async (documentInfo: {
    documentIds: string[];
    documentProperties: Partial<DocumentObjectProperties>;
  }) => {
    await updateDocumentProperties(
      documentInfo.documentIds,
      documentInfo.documentProperties,
    );
  };

  const updateDocumentObjectMutation = useDocumentObjectMutation(
    updateDocumentObjectHandler,
    dealId,
  );

  const lastModifiedDate = documentObject.properties.fileLastModifiedDate
    ? format(parseISO(documentObject.properties.fileLastModifiedDate), 'P')
    : '';

  const uploadFileHandler = async (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      const fileName = await uploadFile(dealId, 'documents', file);
      successHandler('File successfully uploaded!');
      await updateDocumentProperties([documentObject.id], {
        s3FileName: fileName,
      });
    }
  };

  const uploadFileMutation = useDocumentObjectMutationForUploadProperties(
    uploadFileHandler,
    dealId,
    documentObject,
  );

  const clearAssociationList = useCallback(() => {
    setSelectedDocuments([]);
    if (customObjectAssociations?.length) {
      updateDocumentObjectMutation.mutate({
        documentIds: customObjectAssociations?.map((object) => object.id),
        documentProperties: {
          s3FileName: '',
          fileLastModifiedDate: '',
          status: statusOptions[0].hubspotName,
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customObjectAssociations]);

  const updateAssociationProperties = useCallback(
    (
      oldPropertyKey: string,
      oldPropertyValue: string | undefined,
      newProperties: Partial<DocumentObjectProperties>,
    ) => {
      const documentIdsToUpdate = customObjectAssociations
        ?.filter((document) =>
          Object.entries(document.properties).find(
            ([propertyKey, propertyValue]) =>
              propertyKey === oldPropertyKey &&
              propertyValue === oldPropertyValue,
          ),
        )
        .map((document) => document.id);

      if (documentIdsToUpdate?.length) {
        updateDocumentObjectMutation.mutate({
          documentIds: documentIdsToUpdate,
          documentProperties: newProperties,
        });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [customObjectAssociations],
  );

  useEffect(() => {
    if (customObjectAssociations)
      setSelectedDocuments(customObjectAssociations);
  }, [customObjectAssociations]);

  return (
    <>
      {/* Colored Status Indicator */}
      <TableCell align="center" sx={statusColumnStyle}>
        <Box
          sx={{
            width: '12px',
            height: '12px',
            backgroundColor:
              documentObject.properties.status !==
                DOCUMENT_STATUSES.IQ_CONFIRMED &&
              documentObject.properties.status !==
                DOCUMENT_STATUSES.IR_CONFIRMED
                ? getStatusColor(documentObject.properties.status)
                : null,
            border: `4px solid ${getStatusColor(
              documentObject.properties.status,
            )}`,
            borderRadius: '50%',
            margin: '0 auto',
          }}
        />
      </TableCell>

      {/* File Name and Upload / Download Buttons  */}
      <TableCell sx={documentNameColumnStyle}>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <TableBoldedTypography
            sx={{ maxWidth: '70%', wordWrap: 'break-word' }}
            text={documentObject.properties.displayName}
          />
          {!isCustomObject && !isReadOnly && (
            <UploadButton mutationMethod={uploadFileMutation} />
          )}
        </Box>
        {!!documentObject.properties.s3FileName && (
          <DownloadButton
            dealId={dealId}
            fileInformation={{
              fileName: documentObject.properties.s3FileName,
              fileType: documentIsACheck(documentObject.properties.displayName)
                ? 'checks'
                : 'documents',
            }}
            isUploading={uploadFileMutation.isLoading}
          />
        )}
      </TableCell>

      {/* Document Last Modified Date */}
      <TableCell sx={uploadDateColumnStyle}>
        <Tooltip title={lastModifiedDate} enterDelay={0}>
          <Typography
            sx={{
              textOverflow: 'ellipsis',
              overflow: 'hidden',
            }}
          >
            {lastModifiedDate}
          </Typography>
        </Tooltip>
      </TableCell>

      {/* Option to Rotate PDFs and Images  */}
      {!isReadOnly && (
        <TableCell sx={rotateFileColumnStyle}>
          {!!documentObject.properties.s3FileName &&
            documentObject.properties.contentType &&
            allowedContentTypes(documentObject.properties.contentType) && (
              <RotateDropdownMenu
                dealId={dealId}
                fileName={documentObject.properties.s3FileName}
              />
            )}
        </TableCell>
      )}

      {/* Select which documents in HubSpot a custom file upload is associated with */}
      {isCustomObject && !isLoading && (
        <TableCell sx={dataAssociationColumnStyle}>
          <DataAssociationsDropdownMenu
            dealId={dealId}
            documentObject={documentObject}
            selectedDocuments={selectedDocuments}
            setSelectedDocuments={setSelectedDocuments}
            clearAssociationList={clearAssociationList}
            updateDocumentObjectMutation={updateDocumentObjectMutation}
            isReadOnly={isReadOnly}
          />
        </TableCell>
      )}

      {/* See / Change Status of File */}
      {isReadOnly ? (
        <TableCell sx={dataStatusColumnStyle}>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <Box
              sx={{
                width: '8px',
                minWidth: '8px',
                height: '8px',
                backgroundColor:
                  documentObject.properties.status !==
                    DOCUMENT_STATUSES.IQ_CONFIRMED &&
                  documentObject.properties.status !==
                    DOCUMENT_STATUSES.IR_CONFIRMED
                    ? getStatusColor(documentObject.properties.status)
                    : null,
                border: `2px solid ${getStatusColor(
                  documentObject.properties.status,
                )}`,
                borderRadius: '50%',
                margin: '0 auto',
              }}
            />
            <Typography
              sx={{
                textOverflow: 'ellipsis',
                overflow: 'hidden',
                paddingLeft: 1,
                flexGrow: 1,
              }}
            >
              {documentObject.properties.status}
            </Typography>
          </Box>
        </TableCell>
      ) : (
        <TableCell sx={dataStatusColumnStyle}>
          <FormControl sx={dataStatusColumnMenuButtonStyle} size="small">
            <Select
              value={documentObject.properties.status}
              onChange={(event) => {
                const otherPropertiesToUpdate =
                  event.target.value === DOCUMENT_STATUSES.NOT_YET_RECEIVED
                    ? { s3FileName: '', fileLastModifiedDate: '' }
                    : { invalidReason: '' };
                if (isCustomObject) {
                  if (
                    event.target.value === DOCUMENT_STATUSES.NOT_YET_RECEIVED
                  ) {
                    clearAssociationList();
                  } else if (event.target.value !== DOCUMENT_STATUSES.NA) {
                    updateAssociationProperties(
                      'status',
                      documentObject.properties.status,
                      {
                        status: event.target.value,
                        ...otherPropertiesToUpdate,
                      },
                    );
                  }
                }
                updateDocumentObjectMutation.mutate({
                  documentIds: [documentObject.id],
                  documentProperties: {
                    status: event.target.value,
                    ...(!isCustomObject ? otherPropertiesToUpdate : undefined),
                  },
                });
              }}
              displayEmpty
            >
              {statusOptions.map((statusOption) => (
                <MenuItem
                  key={statusOption.hubspotName}
                  value={statusOption.hubspotName}
                >
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                    }}
                  >
                    <Box
                      sx={{
                        width: '8px',
                        minWidth: '8px',
                        height: '8px',
                        backgroundColor:
                          statusOption.hubspotName !==
                            DOCUMENT_STATUSES.IQ_CONFIRMED &&
                          statusOption.hubspotName !==
                            DOCUMENT_STATUSES.IR_CONFIRMED
                            ? getStatusColor(statusOption.hubspotName)
                            : null,
                        border: `2px solid ${getStatusColor(
                          statusOption.hubspotName,
                        )}`,
                        borderRadius: '50%',
                        margin: '0 auto',
                      }}
                    />
                    <Typography
                      sx={{
                        textOverflow: 'ellipsis',
                        overflow: 'hidden',
                        paddingLeft: 1,
                        flexGrow: 1,
                      }}
                    >
                      {statusOption.displayName}
                    </Typography>
                  </Box>
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </TableCell>
      )}

      {/* Select reason why the document is not valid */}
      {!isReadOnly && (
        <TableCell sx={invalidReasonsColumnStyle}>
          <FormControl sx={invalidReasonsColumnMenuButtonStyle} size="small">
            {!isLoadingInvalidReasonOptions &&
              documentObject.properties.status ===
                DOCUMENT_STATUSES.NOT_YET_RECEIVED && (
                <>
                  <Select
                    value={documentObject.properties.invalidReason ?? ''}
                    displayEmpty
                    onChange={(event) => {
                      updateAssociationProperties(
                        'invalidReason',
                        documentObject.properties.invalidReason,
                        { invalidReason: event.target.value },
                      );
                      updateDocumentObjectMutation.mutate({
                        documentIds: [documentObject.id],
                        documentProperties: {
                          invalidReason: event.target.value,
                        },
                      });
                    }}
                  >
                    {invalidReasons?.map((reason) =>
                      reason.value === '' ? (
                        <MenuItem key="none" value="">
                          Select reason invalidated
                        </MenuItem>
                      ) : (
                        <MenuItem key={reason.value} value={reason.value}>
                          {reason.label}
                        </MenuItem>
                      ),
                    )}
                  </Select>
                </>
              )}
          </FormControl>
        </TableCell>
      )}

      {/* Opens modal that can change the document display name, add notes to the document, */}
      {/* and copy the associated file to the file requests folder in the s3 bucket */}
      {!isReadOnly && (
        <TableCell align="center" sx={noteColumnStyle}>
          <EditDocumentModal documentObject={documentObject} dealId={dealId} />
        </TableCell>
      )}
    </>
  );
};
