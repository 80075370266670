import axios, { AxiosError } from 'axios';
import { toast, Zoom } from 'react-toastify';
import { DocumentObject } from 'types';
import { DOCUMENT_STATUSES, ERC_YEARS } from './constants';

export const allowedContentTypes = (contentType: string) => {
  switch (contentType) {
    case 'application/pdf':
    case 'image/jpeg':
    case 'image/png':
      return true;
    default:
      return false;
  }
};

export const dateToYYYYMMDD = (date: Date) => {
  const year = date.getFullYear().toString();
  const month = `0${(date.getMonth() + 1).toString()}`.slice(-2);
  const day = `0${date.getDate()}`.slice(-2);
  return `${year}-${month}-${day}`;
};

const getStatusPriority = (status: string) => {
  switch (status) {
    case DOCUMENT_STATUSES.PENDING_REVIEW:
      return 1;
    case DOCUMENT_STATUSES.NOT_YET_RECEIVED:
      return 2;
    case DOCUMENT_STATUSES.IQ_CONFIRMED:
      return 3;
    case DOCUMENT_STATUSES.IR_CONFIRMED:
      return 4;
    case DOCUMENT_STATUSES.IQ_QC_CONFIRMED:
      return 5;
    default:
      return 6;
  }
};

export const sortByStatus = (
  documentObject1: DocumentObject,
  documentObject2: DocumentObject,
) => {
  const documentObject1StatusPriority = getStatusPriority(
    documentObject1.properties.status,
  );
  const documentObject2StatusPriority = getStatusPriority(
    documentObject2.properties.status,
  );
  return documentObject1StatusPriority - documentObject2StatusPriority;
};

export const filterAssociatedDocumentObjects = (
  hubspotDocumentObjects: DocumentObject[] | undefined,
  fileName?: string,
) =>
  hubspotDocumentObjects?.filter(
    (document) =>
      ERC_YEARS.includes(document.properties.year) &&
      !!fileName &&
      document.properties.s3FileName === fileName,
  );

export const errorHandler = (error: unknown) => {
  console.error(error);

  const errorMessage =
    ((error as AxiosError).response?.data as string) ??
    (error as Error).message ??
    (error as Error).toString();

  toast.error(errorMessage, {
    toastId: errorMessage,
    position: 'top-center',
    autoClose: 5000,
    transition: Zoom,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  });
};

export const successHandler = (message: string) => {
  toast.success(message, {
    position: 'top-center',
    autoClose: 5000,
    transition: Zoom,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  });
};

export const infoHandler = (message: string, toastId?: string) => {
  toast.info(message, {
    toastId,
    position: 'top-center',
    autoClose: false,
    transition: Zoom,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  });
};

export const lambdaAxios = axios.create({
  baseURL: `https://${process.env.REACT_APP_BACKEND_URL}`,
});

export const generateHeaders = (token: string) => ({
  headers: {
    'Content-Type': 'application/json',
    Authorization: token,
  },
});
