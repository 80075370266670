import { Box, createTheme, GlobalStyles, ThemeProvider } from '@mui/material';
import { Amplify } from 'aws-amplify';
import React from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { ReactQueryDevtools } from 'react-query/devtools';
import { GLOBAL_THEME } from 'themes/globalTheme';
import Router from './Router';
import { CriqueGroteskWoff, CriqueGroteskWoff2 } from './assets/fonts';

Amplify.configure({
  Auth: {
    userPoolId: process.env.REACT_APP_COGNITO_USER_POOL_ID, // UserPool ID
    region: 'us-east-1',
    userPoolWebClientId: process.env.REACT_APP_COGNITO_WEB_CLIENT_ID, // WebClientId
    ...(process.env.REACT_APP_COGNITO_USER_POOL_DOMAIN_PREFIX
      ? {
          oauth: {
            domain: `${process.env.REACT_APP_COGNITO_USER_POOL_DOMAIN_PREFIX}.auth.us-east-1.amazoncognito.com`,
            scope: [
              'phone',
              'email',
              'profile',
              'openid',
              'aws.cognito.signin.user.admin',
            ],
            redirectSignIn: `${window.location.origin}/`,
            redirectSignOut: `${window.location.origin}/`,
            responseType: 'token',
          },
        }
      : {}),
  },
});

const queryClient = new QueryClient();

function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <GlobalStyles
        styles={{
          '@font-face': {
            fontFamily: 'Crique Grotesk',
            src:
              `url(${CriqueGroteskWoff2}) format("woff2"),` +
              `url(${CriqueGroteskWoff}) format("woff")`,
          },
          body: {
            margin: 0,
            backgroundColor: '#F5F5F5',
          },
        }}
      />
      <ThemeProvider theme={createTheme(GLOBAL_THEME)}>
        <Box
          sx={{
            paddingLeft: '10vw',
            paddingRight: '10vw',
          }}
        >
          <Router />
        </Box>
      </ThemeProvider>
      <ToastContainer />
      <ReactQueryDevtools />
    </QueryClientProvider>
  );
}

export default App;
