import { Footer } from 'components/Footer/Footer';
import { DealHeader } from 'components/Header/DealHeader';
import { Header } from 'components/Header/Header';
import { LoadingIndicator } from 'components/LoadingIndicator/LoadingIndicator';
import { CPADocumentsTable } from 'components/Tables/CPADocumentsTable';
import { CreditsTable } from 'components/Tables/CreditsTable';
import { OtherDocumentsTable } from 'components/Tables/OtherDocumentsTable';
import { NotesTable } from 'components/Tables/NotesTable';
import { documentObjectsQuery } from 'dataLayer/Document/get';
import { ERC_YEARS } from 'helpers/constants';
import { sortByStatus } from 'helpers/utils';
import { FunctionComponent } from 'react';
import { RouteProps, useParams } from 'react-router-dom';
import { useQuery } from 'react-query';

export const DealCPA: FunctionComponent<RouteProps> = () => {
  const { dealId } = useParams() as { dealId: string };
  const { data: documentObjects, isLoading } = useQuery(
    documentObjectsQuery(dealId),
  );

  return (
    <>
      <Header />
      <DealHeader dealId={dealId} />
      <CPADocumentsTable dealId={dealId} />
      <CreditsTable dealId={dealId} />

      {isLoading ? (
        <LoadingIndicator />
      ) : (
        <OtherDocumentsTable
          dealId={dealId}
          documents={documentObjects
            ?.filter(
              (document) => !ERC_YEARS.includes(document.properties.year),
            )
            .sort(sortByStatus)}
        />
      )}

      <NotesTable dealId={dealId} />
      <Footer />
    </>
  );
};
