import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { DownloadButton } from 'components/Buttons/DownloadButton';
import { UploadButton } from 'components/Buttons/UploadButton';
import { LoadingIndicator } from 'components/LoadingIndicator/LoadingIndicator';
import { TableBoldedTypography } from 'components/Typography/TableBoldedTypography';
import { TableCommonTypography } from 'components/Typography/TableCommonTypography';
import { TableHeaderTypography } from 'components/Typography/TableHeaderTypography';
import {
  useMutationFor2848Files,
  useMutationFor8821Files,
  useMutationForStatementOfFactsFiles,
  useMutationForTaxEngagementFiles,
} from 'dataLayer/Credit/mutations';
import { updateDealProperties } from 'dataLayer/Deal/api';
import {
  form2848FilesQuery,
  form8821FilesQuery,
  statementOfFactsFilesQuery,
  taxEngagementFilesQuery,
} from 'dataLayer/Deal/get';
import { archiveFile, uploadFile, uploadCPAFile } from 'dataLayer/Files/api';
import { dateToYYYYMMDD, successHandler } from 'helpers/utils';
import { ChangeEvent, FunctionComponent } from 'react';
import { useQuery } from 'react-query';

interface IProps {
  dealId: string;
}

export const CPADocumentsTable: FunctionComponent<IProps> = ({ dealId }) => {
  const { data: filesFor2848Form, isLoading: isLoadingFilesFor2848Form } =
    useQuery(form2848FilesQuery(dealId));

  const { data: filesFor8821Form, isLoading: isLoadingFilesFor8821Form } =
    useQuery(form8821FilesQuery(dealId));

  const {
    data: filesForTaxEngagement,
    isLoading: isLoadingFilesForTaxEngagement,
  } = useQuery(taxEngagementFilesQuery(dealId));

  const {
    data: filesForStatementOfFacts,
    isLoading: isLoadingFilesForStatementOfFacts,
  } = useQuery(statementOfFactsFilesQuery(dealId));

  const upload2848FileHandler = async (
    event: ChangeEvent<HTMLInputElement>,
  ) => {
    if (filesFor2848Form) {
      await Promise.all(
        filesFor2848Form.map(async (formFile) => {
          await archiveFile(dealId, formFile.name, '2848');
        }),
      );
    }
    const file = event.target.files?.[0];
    if (file) {
      await uploadFile(dealId, '2848', file);
      await updateDealProperties([dealId], {
        n2848FormUploadDate: dateToYYYYMMDD(new Date()),
      });
      successHandler('File successfully uploaded!');
    }
  };

  const upload8821FileHandler = async (
    event: ChangeEvent<HTMLInputElement>,
  ) => {
    if (filesFor8821Form) {
      await Promise.all(
        filesFor8821Form.map(async (formFile) => {
          await archiveFile(dealId, formFile.name, '8821');
        }),
      );
    }
    const file = event.target.files?.[0];
    if (file) {
      await uploadFile(dealId, '8821', file);
      await updateDealProperties([dealId], {
        n8821FormUploadDate: dateToYYYYMMDD(new Date()),
      });
      successHandler('File successfully uploaded!');
    }
  };

  const uploadTaxEngagementFileHandler = async (
    event: ChangeEvent<HTMLInputElement>,
  ) => {
    if (filesForTaxEngagement) {
      await Promise.all(
        filesForTaxEngagement.map(async (taxEngagementFile) => {
          await archiveFile(dealId, taxEngagementFile.name, 'taxEngagement');
        }),
      );
    }
    const file = event.target.files?.[0];
    if (file) {
      await uploadFile(dealId, 'taxEngagement', file);
      await updateDealProperties([dealId], {
        taxEngagementLetterUploadDate: dateToYYYYMMDD(new Date()),
      });
      successHandler('File successfully uploaded!');
    }
  };

  const uploadStatementOfFactsFileHandler = async (
    event: ChangeEvent<HTMLInputElement>,
  ) => {
    if (filesForStatementOfFacts) {
      await Promise.all(
        filesForStatementOfFacts.map(async (statementOfFactsFile) => {
          await archiveFile(
            dealId,
            statementOfFactsFile.name,
            'statementOfFacts',
          );
        }),
      );
    }
    const file = event.target.files?.[0];
    if (file) {
      await uploadCPAFile(
        dealId,
        file,
        'statementOfFacts',
        'Statement of Facts',
        true,
      );
      await updateDealProperties([dealId], {
        statementOfFactsUploadDate: dateToYYYYMMDD(new Date()),
      });
      successHandler('File successfully uploaded!');
    }
  };

  const upload2848FormMutation = useMutationFor2848Files(
    upload2848FileHandler,
    dealId,
  );

  const upload8821FormMutation = useMutationFor8821Files(
    upload8821FileHandler,
    dealId,
  );

  const uploadTaxEngagementMutation = useMutationForTaxEngagementFiles(
    uploadTaxEngagementFileHandler,
    dealId,
  );

  const uploadStatementOfFactsMutation = useMutationForStatementOfFactsFiles(
    uploadStatementOfFactsFileHandler,
    dealId,
  );

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          marginTop: 4,
          marginBottom: 2,
        }}
      >
        <Typography
          fontSize="20px"
          color="portalPalettes.darkgray"
          fontWeight="700"
        >
          2848 Form/8821 Form/Tax Engagement Letter/Statement of Facts
        </Typography>
        <Typography fontSize="16px" color="#777777">
          All uploads and edits are saved automatically
        </Typography>
      </Box>
      <TableContainer>
        <Table>
          <TableHead
            sx={{
              height: '75px',
            }}
          >
            <TableRow>
              <TableCell>
                <TableHeaderTypography text="Form Name" />
                <TableCommonTypography
                  text="Upload Document Name"
                  sx={{ paddingTop: 1 }}
                />
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell>
                {isLoadingFilesFor2848Form ? (
                  <LoadingIndicator />
                ) : (
                  <>
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                      <TableBoldedTypography text="2848 Form" />
                      <UploadButton mutationMethod={upload2848FormMutation} />
                    </Box>
                    {!!filesFor2848Form?.[0]?.name && (
                      <DownloadButton
                        dealId={dealId}
                        fileInformation={{
                          fileName: filesFor2848Form[0].name,
                          fileType: '2848',
                        }}
                        isUploading={upload2848FormMutation.isLoading}
                      />
                    )}
                  </>
                )}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                {isLoadingFilesFor8821Form ? (
                  <LoadingIndicator />
                ) : (
                  <>
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                      <TableBoldedTypography text="8821 Form" />
                      <UploadButton mutationMethod={upload8821FormMutation} />
                    </Box>
                    {!!filesFor8821Form?.[0]?.name && (
                      <DownloadButton
                        dealId={dealId}
                        fileInformation={{
                          fileName: filesFor8821Form[0].name,
                          fileType: '8821',
                        }}
                        isUploading={upload8821FormMutation.isLoading}
                      />
                    )}
                  </>
                )}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                {isLoadingFilesForTaxEngagement ? (
                  <LoadingIndicator />
                ) : (
                  <>
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                      <TableBoldedTypography text="Tax Engagement Letter" />
                      <UploadButton
                        mutationMethod={uploadTaxEngagementMutation}
                      />
                    </Box>
                    {!!filesForTaxEngagement?.[0]?.name && (
                      <DownloadButton
                        dealId={dealId}
                        fileInformation={{
                          fileName: filesForTaxEngagement[0].name,
                          fileType: 'taxEngagement',
                        }}
                        isUploading={uploadTaxEngagementMutation.isLoading}
                      />
                    )}
                  </>
                )}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                {isLoadingFilesForStatementOfFacts ? (
                  <LoadingIndicator />
                ) : (
                  <>
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                      <TableBoldedTypography text="Statement of Facts" />
                      <UploadButton
                        mutationMethod={uploadStatementOfFactsMutation}
                      />
                    </Box>
                    {!!filesForStatementOfFacts?.[0]?.name && (
                      <DownloadButton
                        dealId={dealId}
                        fileInformation={{
                          fileName: filesForStatementOfFacts[0].name,
                          fileType: 'statementOfFacts',
                        }}
                        isUploading={uploadStatementOfFactsMutation.isLoading}
                      />
                    )}
                  </>
                )}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};
