import { Box, CircularProgress, SxProps } from '@mui/material';
import { FunctionComponent } from 'react';

interface IProps {
  size?: number;
  sx?: SxProps;
}

export const LoadingIndicator: FunctionComponent<IProps> = ({ size, sx }) => (
  <Box sx={{ display: 'flex', justifyContent: 'center', ...sx }}>
    <CircularProgress size={size} color="inherit" />
  </Box>
);
