import {
  Box,
  Typography,
  OutlinedInput,
  CircularProgress,
  Button,
} from '@mui/material';
import { uploadCustomDocument } from 'dataLayer/Document/api';
import { useDocumentUploadMutation } from 'dataLayer/Document/mutations';
import { errorHandler } from 'helpers/utils';
import { FunctionComponent, useState, useRef, useEffect } from 'react';

const MEGABYTE = 1048576;

const ACCEPTED_FILE_TYPES = [
  'image/png',
  'image/jpeg',
  'application/pdf',
  'application/msword',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  'text/csv',
  'application/vnd.ms-excel',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
];

export const CustomDocumentUpload: FunctionComponent<{ dealId: string }> = ({
  dealId,
}) => {
  const [displayNameToUpload, setDisplayNameToUpload] = useState('');
  const [selectedFile, setSelectedFile] = useState<File>();
  const [submitDisabled, setSubmitDisabled] = useState(false);
  const fileRef = useRef<HTMLInputElement>(null);

  const clearFileInput = () => {
    if (fileRef?.current) fileRef.current.value = '';
  };
  const handleSubmit = async (documentInfo: {
    file: File | undefined;
    documentName: string;
  }) => {
    if (!documentInfo.file) return;

    setSubmitDisabled(true);
    await uploadCustomDocument(
      documentInfo.file,
      documentInfo.documentName,
      dealId,
    );
  };

  const handleFileSelected = (file: File | undefined) => {
    if (file && file.size > 200 * MEGABYTE) {
      clearFileInput();
      setSelectedFile(undefined);
      errorHandler(
        'Selected file is too large, please select a file under 200MB',
      );
      return;
    }
    setSelectedFile(file);
  };

  const uploadCustomDocumentMutation = useDocumentUploadMutation(
    handleSubmit,
    dealId,
  );

  const isSubmitDisabled = () =>
    submitDisabled ||
    uploadCustomDocumentMutation.isLoading ||
    !displayNameToUpload ||
    !selectedFile;

  useEffect(() => {
    if (uploadCustomDocumentMutation.isError) setSubmitDisabled(false);
    if (!uploadCustomDocumentMutation.isSuccess) return;

    setDisplayNameToUpload('');
    clearFileInput();
    setSubmitDisabled(false);
  }, [
    uploadCustomDocumentMutation.isError,
    uploadCustomDocumentMutation.isSuccess,
  ]);

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        backgroundColor: 'white',
        minHeight: '40px',
        maxWidth: '50vw',
        padding: '10px',
        boxShadow: '0px 2px 8px #00000014',
        borderRadius: '8px',
        marginBottom: '16px',
      }}
    >
      <Typography
        sx={{
          paddingRight: 1.5,
          color: 'rgba(0, 0, 0, 0.87);',
          span: {
            color: 'red',
          },
        }}
      >
        Name of Document:
      </Typography>
      <OutlinedInput
        type="text"
        sx={{
          height: '40px',
          marginRight: '40px',
        }}
        onChange={(e) => setDisplayNameToUpload(e.target.value)}
        value={displayNameToUpload}
        disabled={uploadCustomDocumentMutation.isLoading}
      />
      <input
        type="file"
        multiple={false}
        onChange={(event) => handleFileSelected(event.target.files?.[0])}
        accept={ACCEPTED_FILE_TYPES.join(',')}
        disabled={uploadCustomDocumentMutation.isLoading}
        ref={fileRef}
      />
      {uploadCustomDocumentMutation.isLoading ? (
        <CircularProgress size={20} sx={{ paddingX: 1 }} />
      ) : (
        <Button
          variant="contained"
          disabled={isSubmitDisabled()}
          onClick={() =>
            uploadCustomDocumentMutation.mutate({
              file: selectedFile,
              documentName: displayNameToUpload,
            })
          }
        >
          Submit
        </Button>
      )}
    </Box>
  );
};
