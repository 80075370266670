import { Edit } from '@mui/icons-material';
import { Box, IconButton } from '@mui/material';
import { UpdateCreditModal } from 'components/Modals/UpdateCreditModal';
import { FocusEvent, FunctionComponent, useState } from 'react';

import NumberFormat from 'react-number-format';

interface IProps {
  disabled?: boolean;
  isReadOnly?: boolean;
  defaultValue?: number;
  onChange: (input: string) => void;
  onChangeOverride?: (email: string, reason: string, input: string) => void;
}

export const CurrencyInput: FunctionComponent<IProps> = ({
  disabled,
  isReadOnly,
  defaultValue,
  onChange,
  onChangeOverride,
}) => {
  const [isUpdateModalOpen, setIsUpdateModalOpen] = useState(false);
  const [currentVal, setCurrentVal] = useState(String(defaultValue ?? 0));

  const confirmUpdate = (email: string, reason: string, value: string) => {
    setCurrentVal(value);
    setIsUpdateModalOpen(false);
    if (!onChangeOverride) {
      console.error('cannot override');
      onChange(value);
      return;
    }

    onChangeOverride(email, reason, value);
  };

  return (
    <>
      <Box sx={{ display: 'flex' }}>
        <Box
          sx={{
            borderRadius: '4px 0 0 4px',
            borderStyle: 'solid',
            borderWidth: '1px 0 1px 1px',
            borderColor: '#0000003B',
            height: 'inherit',
            paddingY: 1,
            paddingX: 1.5,
          }}
        >
          $
        </Box>
        <NumberFormat
          disabled={disabled || isReadOnly}
          thousandSeparator
          decimalSeparator="."
          decimalScale={2}
          fixedDecimalScale
          style={{
            borderRadius: disabled ? '0' : '0 4px 4px 0',
            borderStyle: 'solid',
            borderWidth: '1px',
            borderColor: '#0000003B',
            paddingLeft: '8px',
          }}
          type="text"
          value={currentVal}
          onValueChange={(values: { value: string }) => {
            setCurrentVal(values.value);
            onChange(values.value);
          }}
          onFocus={(event: FocusEvent<HTMLInputElement>) =>
            event.target?.select()
          }
        />
        {disabled && !isReadOnly && (
          <Box
            sx={{
              borderRadius: '0 4px 4px 0',
              borderStyle: 'solid',
              borderWidth: '1px 1px 1px 0px',
              borderColor: '#0000003B',
              height: 'inherit',
            }}
          >
            <IconButton size="small" onClick={() => setIsUpdateModalOpen(true)}>
              <Edit />
            </IconButton>
          </Box>
        )}
      </Box>
      <UpdateCreditModal
        isOpen={isUpdateModalOpen}
        currentValue={defaultValue}
        handleClose={() => setIsUpdateModalOpen(false)}
        handleConfirm={confirmUpdate}
      />
    </>
  );
};
