import { Footer } from 'components/Footer/Footer';
import { Header } from 'components/Header/Header';
import { SubHeader } from 'components/Header/SubHeader';
import { LoadingIndicator } from 'components/LoadingIndicator/LoadingIndicator';
import { CSCDocumentsTable } from 'components/Tables/CSCDocumentsTable';
import { OtherDocumentsTable } from 'components/Tables/OtherDocumentsTable';
import { S3FilesTable } from 'components/Tables/S3FilesTable';
import { documentObjectsQuery } from 'dataLayer/Document/get';
import { ERC_YEARS } from 'helpers/constants';
import { sortByStatus } from 'helpers/utils';
import { FunctionComponent } from 'react';
import { useQuery } from 'react-query';
import { RouteProps, useParams } from 'react-router-dom';

export const DealDocuments: FunctionComponent<RouteProps> = () => {
  const { dealId } = useParams() as { dealId: string };

  const { data: documentObjects, isLoading } = useQuery(
    documentObjectsQuery(dealId),
  );

  return (
    <>
      <Header />
      <SubHeader dealId={dealId} />
      {isLoading ? (
        <LoadingIndicator />
      ) : (
        <>
          {ERC_YEARS.map((year) => {
            if (
              year === 2023 &&
              !documentObjects?.find((document) =>
                (document.properties.displayName ?? '').includes(
                  'Most Recent 941',
                ),
              )
            ) {
              return undefined;
            }

            return (
              <CSCDocumentsTable
                dealId={dealId}
                label={
                  year === 2023 ? 'Most Recently Filed 941 with the IRS' : year
                }
                documents={documentObjects
                  ?.filter((document) => document.properties.year === year)
                  .sort(sortByStatus)}
              />
            );
          })}
          <OtherDocumentsTable
            dealId={dealId}
            documents={documentObjects
              ?.filter(
                (document) => !ERC_YEARS.includes(document.properties.year),
              )
              .sort(sortByStatus)}
          />
        </>
      )}
      <S3FilesTable dealId={dealId} />
      <Footer />
    </>
  );
};
