import { Box, Typography } from '@mui/material';
import { Footer } from 'components/Footer/Footer';
import { DealHeader } from 'components/Header/DealHeader';
import { Header } from 'components/Header/Header';
import { LoadingIndicator } from 'components/LoadingIndicator/LoadingIndicator';
import { dealPropertiesQuery, bqfDisplayNamesQuery } from 'dataLayer/Deal/get';
import { FunctionComponent, useMemo } from 'react';
import { useQuery } from 'react-query';
import { RouteProps, useParams } from 'react-router-dom';
import { DealObjectProperties } from 'types';

const optionalPeoFields = [
  'peoContactName',
  'peoContactPhone',
  'peoContactEmail',
  'peoCompanyName',
  'willPeoWorkWithIR',
  'calculationsFormsNeeded',
  'feeToClient',
  'timeframeWithThePeo',
  'whenWillPeoFileAmendments',
];

const sortedDealProperties = [
  'companyName',
  'tradeNameOrDBAIfApplicable',
  'contractSignerFullName',
  'contractSignerEmail',
  'title',
  'addressOnFileWithIRS',
  'addressLine2OnFileWithIRS',
  'cityOnFileWithIRS',
  'stateOnFileWithIRS',
  'postalCodeOnFileWithIRS',
  'pocFullName',
  'pocEmail',
  'pointOfContactPhoneNumber',
  'websiteUrl',
  'caseNotesForCPA',
  'averageNumberOfW2EmployeesOnPayrollFor2019',
  'averageNumberOfPartTimeW2EmployeesOnPayrollFor2019',
  'entityFilingType',
  'industry',
  'checkAnyOfTheFollowingThatDelayedDisruptedOrNegativelyImpactedYourBusinessAsAResult',
  'covidHasAffectedMeBecause',
  'majorityOwner',
  'doesYourCompanyOrItsPartnersHave80OrMoreOwnershipInAnyOtherBusinesses',
  'haveYouQualifiedForAndClaimedAnyFederalGovernmentIncentivesOrTaxCreditsWithinThePast',
  'creditProgram1',
  'otherCreditProgram1',
  'howMuchReceivedCreditProgram1',
  'whenWereFundsDisbursedCreditProgram1',
  'doYouHaveSupportingDocumentsCreditProgram1',
  'creditProgram2',
  'otherCreditProgram2',
  'howMuchReceivedCreditProgram2',
  'whenWereFundsDisbursedCreditProgram2',
  'doYouHaveSupportingDocumentsCreditProgram2',
  'creditProgram3',
  'otherCreditProgram3',
  'howMuchReceivedCreditProgram3',
  'whenWereFundsDisbursedCreditProgram3',
  'doYouHaveSupportingDocumentsCreditProgram3',
  'atAnyPointInTimeDuring2020Or2021DidYouUtilizeAPeoProfessionalEmployerOrganization',
  ...optionalPeoFields,
  'doYouProvideHealthcareForYourEmployees',
  'numberOfFamilyMembersOnPayroll',
  'otherThanOwnersSpousesListAnyImmediateFamilyMembersOnPayroll',
  'haveYouAcceptedFundsFromEitherAPppOrPpp2PaycheckProtectionProgramCloned',
  'whatWasYour1stPppPaymentDisbursementDate',
  'ppp1CoveredPeriod',
  'whatWasYour1stPppPaymentDisbursementAmount',
  'hasYour1stPppLoanBeenForgiven',
  'whatWasYour2ndPppPaymentDisbursementDate',
  'ppp2CoveredPeriod',
  'whatWasYour2ndPppDisbursementAmount',
  'hasYour2ndPppLoanBeenForgiven_',
];

export const BusinessQualifyForm: FunctionComponent<RouteProps> = () => {
  const { dealId } = useParams() as { dealId: string };
  const { data: dealProperties } = useQuery(dealPropertiesQuery(dealId));

  const { data: bqfDisplayNames, isLoading: isLoadingBqfDisplayNames } =
    useQuery(bqfDisplayNamesQuery());

  const sortedBqfDisplayNames = useMemo(() => {
    let filteredBqfDisplayNames = Object.entries(bqfDisplayNames ?? []);

    if (!dealProperties?.properties.creditProgram1) {
      filteredBqfDisplayNames = filteredBqfDisplayNames.filter(
        ([key]) => !key.toLowerCase().includes('creditprogram1'),
      );
    }
    if (!dealProperties?.properties.creditProgram2) {
      filteredBqfDisplayNames = filteredBqfDisplayNames.filter(
        ([key]) => !key.toLowerCase().includes('creditprogram2'),
      );
    }
    if (!dealProperties?.properties.creditProgram3) {
      filteredBqfDisplayNames = filteredBqfDisplayNames.filter(
        ([key]) => !key.toLowerCase().includes('creditprogram3'),
      );
    }
    if (dealProperties?.properties.creditProgram1 !== 'Other') {
      filteredBqfDisplayNames = filteredBqfDisplayNames.filter(
        ([key]) => key !== 'otherCreditProgram1',
      );
    }
    if (dealProperties?.properties.creditProgram2 !== 'Other') {
      filteredBqfDisplayNames = filteredBqfDisplayNames.filter(
        ([key]) => key !== 'otherCreditProgram2',
      );
    }
    if (dealProperties?.properties.creditProgram3 !== 'Other') {
      filteredBqfDisplayNames = filteredBqfDisplayNames.filter(
        ([key]) => key !== 'otherCreditProgram3',
      );
    }

    if (
      dealProperties?.properties
        .atAnyPointInTimeDuring2020Or2021DidYouUtilizeAPeoProfessionalEmployerOrganization !==
      'Yes'
    ) {
      filteredBqfDisplayNames = filteredBqfDisplayNames.filter(
        ([key]) => !optionalPeoFields.includes(key),
      );
    }

    return filteredBqfDisplayNames.sort(
      ([key1], [key2]) =>
        sortedDealProperties.findIndex((prop) => prop === key1) -
        sortedDealProperties.findIndex((prop) => prop === key2),
    );
  }, [bqfDisplayNames, dealProperties]);

  return (
    <>
      <Header />
      <DealHeader dealId={dealId} />
      {isLoadingBqfDisplayNames ? (
        <LoadingIndicator />
      ) : (
        <>
          {sortedBqfDisplayNames.map(([key, value]) => (
            <Box
              sx={{
                backgroundColor: 'white',
                margin: 1,
                borderRadius: '8px',
                border: 1,
                borderColor: 'lightgray',
              }}
            >
              <Typography
                key={`${key} title`}
                fontWeight="500"
                sx={{
                  fontWeight: 'bold',
                  color: 'portalPalettes.green',
                  backgroundColor: '#DDE4EF33',
                  padding: 1,
                  borderBottom: 1,
                  borderColor: 'lightgray',
                }}
              >
                {value}
              </Typography>
              {key ===
              'checkAnyOfTheFollowingThatDelayedDisruptedOrNegativelyImpactedYourBusinessAsAResult' ? (
                dealProperties?.properties[key as keyof DealObjectProperties]
                  ?.split(';')
                  .map((item) => (
                    <Typography
                      key={item}
                      sx={{ paddingLeft: 2, paddingY: 1.5 }}
                    >
                      &bull; {item}
                    </Typography>
                  ))
              ) : (
                <Typography
                  key={`${key}title`}
                  sx={{
                    paddingLeft: 2,
                    paddingY: 1.5,
                    whiteSpace: 'pre-line ',
                  }}
                >
                  {
                    dealProperties?.properties[
                      key as keyof DealObjectProperties
                    ]
                  }
                </Typography>
              )}
            </Box>
          ))}
        </>
      )}
      <Footer />
    </>
  );
};
