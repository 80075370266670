import { getUserToken } from 'dataLayer/Cognito';
import { generateHeaders, lambdaAxios } from 'helpers/utils';
import {
  CreateDealTicketProperties,
  DealTicketObject,
  DealTicketProperties,
} from 'types';

const HUBSPOT_TICKETS_URL = 'hubspot/tickets';

export const createDealTicketObject = async (
  dealId: string,
  dealTicketProperties: CreateDealTicketProperties,
) => {
  const token = await getUserToken();
  if (!token) return;

  await lambdaAxios.post(
    `${HUBSPOT_TICKETS_URL}/${dealId}`,
    dealTicketProperties,
    generateHeaders(token),
  );
};

export const getDealTickets = async (dealId: string) => {
  const token = await getUserToken();
  if (!token) return [];

  const response = await lambdaAxios.get<DealTicketObject[]>(
    `${HUBSPOT_TICKETS_URL}/${dealId}`,
    generateHeaders(token),
  );
  return response.data;
};

export const updateDealTicketObjects = async (
  ticketObjectIds: string[],
  dealTicketProperties: Partial<DealTicketProperties>,
) => {
  const token = await getUserToken();
  if (!token) return;
  if (ticketObjectIds.length === 0) return;

  const url = `${HUBSPOT_TICKETS_URL}?hubspotObjectId=${ticketObjectIds.join(
    '&hubspotObjectId=',
  )}`;

  await lambdaAxios.post(url, dealTicketProperties, generateHeaders(token));
};
