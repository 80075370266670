import { Button, Icon, Tooltip } from '@mui/material';
import { FunctionComponent } from 'react';
import { useAsync } from '@react-hookz/web';
import { DownloadIcon } from 'assets/icons';
import { LoadingIndicator } from 'components/LoadingIndicator/LoadingIndicator';
import { TableCommonTypography } from 'components/Typography/TableCommonTypography';
import { errorHandler } from 'helpers/utils';
import { getFile } from 'dataLayer/Files/api';

interface IProps {
  dealId: string;
  fileInformation: { fileName: string; fileType: string };
  isUploading?: boolean;
}

export const DownloadButton: FunctionComponent<IProps> = ({
  dealId,
  fileInformation,
  isUploading,
}) => {
  const [downloadState, downloadAction] = useAsync(async () => {
    try {
      const file = await getFile(
        dealId,
        fileInformation.fileName,
        fileInformation.fileType,
      );
      if (file) window.open(file.url, '_blank');
    } catch (error) {
      errorHandler(error);
    }
  });

  return (
    <Tooltip title={fileInformation.fileName} enterDelay={0}>
      <Button
        disabled={downloadState.status === 'loading' || isUploading}
        disableRipple
        variant="text"
        onClick={downloadAction.execute}
        sx={{
          paddingLeft: 0,
          '&:hover': {
            textDecoration: 'underline',
            backgroundColor: 'transparent',
          },
          maxWidth: 'inherit',
        }}
      >
        <TableCommonTypography
          text={fileInformation.fileName}
          sx={{
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            whiteSpace: 'nowrap',
          }}
        />
        {downloadState.status === 'loading' || isUploading ? (
          <LoadingIndicator size={20} />
        ) : (
          <Icon>
            <img
              style={{ verticalAlign: 'top', paddingTop: '2px' }}
              src={DownloadIcon}
            />
          </Icon>
        )}
      </Button>
    </Tooltip>
  );
};
