import {
  Box,
  Button,
  CircularProgress,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  TextField,
  Typography,
} from '@mui/material';
import { notesQuery } from 'dataLayer/Deal/get';
import { FunctionComponent, useState } from 'react';
import { useQuery } from 'react-query';
import { DealNoteObject, DealNoteProperties } from 'types';
import format from 'date-fns/format';
import { useCreateDealNoteObjectMutation } from 'dataLayer/Deal/mutations';
import { createDealNoteObject } from 'dataLayer/Deal/api';

const getNoteWithFormattedTimestamp = (note: DealNoteObject) =>
  `${format(new Date(note.properties.timestamp), 'MM/dd/yyyy')} -
  ${note.properties.note}`;

interface IProps {
  dealId: string;
}

export const NotesTable: FunctionComponent<IProps> = ({ dealId }) => {
  const { data: noteObjects, isLoading } = useQuery(notesQuery(dealId));

  const [noteValue, setNoteValue] = useState('');

  const createDealNoteObjectHandler = async (
    dealNoteProperties: DealNoteProperties,
  ) => {
    await createDealNoteObject(dealId, dealNoteProperties);
  };

  const createDealNoteMutation = useCreateDealNoteObjectMutation(
    createDealNoteObjectHandler,
    dealId,
  );

  const createNote = () => {
    createDealNoteMutation.mutate({
      timestamp: new Date().toISOString(),
      note: noteValue,
      dealId,
    });
    setNoteValue('');
  };

  return (
    <>
      <Typography
        fontSize="20px"
        color="portalPalettes.darkgray"
        fontWeight="700"
        marginTop={4}
        marginBottom={2}
      >
        Notes
      </Typography>
      {isLoading && <CircularProgress size={20} sx={{ paddingX: 1 }} />}
      {!isLoading && noteObjects && (
        <Box
          sx={{ width: '100%', bgcolor: 'background.paper', marginBottom: 2 }}
        >
          <List>
            {noteObjects.map((note) => (
              <ListItem key={note.id} disablePadding>
                <ListItemButton>
                  <ListItemText primary={getNoteWithFormattedTimestamp(note)} />
                </ListItemButton>
              </ListItem>
            ))}
          </List>
        </Box>
      )}
      <TextField
        fullWidth
        multiline
        id="note"
        label="Note"
        placeholder="Add note..."
        value={noteValue}
        onChange={(event) => setNoteValue(event.currentTarget.value)}
        rows={3}
      />
      <Button
        // disabled={isLoading}
        disableRipple
        variant="outlined"
        onClick={createNote}
        sx={{
          marginTop: 2,
          color: 'portalPalettes.green',
          borderColor: 'portalPalettes.green',
          fontSize: '18px',
        }}
      >
        <Typography fontSize="16px" color="portalPalettes.green">
          Save Note
        </Typography>
      </Button>
    </>
  );
};
