import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { LoadingIndicator } from 'components/LoadingIndicator/LoadingIndicator';
import { CPACreditTableCells } from 'components/Tables/TableCells/CPACreditTableCells';
import { TableCommonTypography } from 'components/Typography/TableCommonTypography';
import { TableHeaderTypography } from 'components/Typography/TableHeaderTypography';
import { creditObjectsQuery } from 'dataLayer/Credit/get';
import { CREDIT_OBJECT_TYPES } from 'helpers/constants';
import { FunctionComponent } from 'react';
import NumberFormat from 'react-number-format';
import { useQuery } from 'react-query';
import {
  checkboxColumnStyle,
  creditObjectNameColumnStyle,
  creditObjectQualifyingCreditsColumnStyle,
  creditObjectQualifyingWagesColumnStyle,
} from '../../pages/Deals/DealCPA/formTableStyles';

interface IProps {
  dealId: string;
  isLoadingDealProperties: boolean;
}

export const ERCACreditsTable: FunctionComponent<IProps> = ({
  dealId,
  isLoadingDealProperties,
}) => {
  const { data: creditObjects, isLoading: isLoadingCreditObjects } = useQuery(
    creditObjectsQuery(dealId),
  );

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          marginTop: 4,
          marginBottom: 2,
        }}
      >
        <Typography
          fontSize="20px"
          color="portalPalettes.darkgray"
          fontWeight="700"
        >
          941x Documents
        </Typography>
      </Box>
      {isLoadingCreditObjects || isLoadingDealProperties ? (
        <LoadingIndicator />
      ) : (
        <TableContainer>
          <Table>
            <TableHead
              sx={{
                height: '75px',
              }}
            >
              <TableRow>
                <TableCell sx={checkboxColumnStyle.containerStyle}>
                  <TableHeaderTypography text="Qualifies" />
                  <TableHeaderTypography text="for credit" />
                </TableCell>
                <TableCell sx={creditObjectNameColumnStyle.containerStyle}>
                  <TableHeaderTypography text="Form Name" />
                  <TableCommonTypography
                    text="Upload Document Name"
                    sx={{ paddingTop: 1 }}
                  />
                </TableCell>
                <TableCell
                  sx={creditObjectQualifyingCreditsColumnStyle.containerStyle}
                >
                  <TableHeaderTypography text="Confirm Qualifying Credits" />
                </TableCell>
                <TableCell
                  sx={creditObjectQualifyingWagesColumnStyle.containerStyle}
                >
                  <TableHeaderTypography text="Confirm Qualifying Wages" />
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {CREDIT_OBJECT_TYPES.map((creditObjectType) => (
                <TableRow
                  key={creditObjectType.hubspotName}
                  sx={{
                    boxShadow: '0px 1px 0px lightgray',
                  }}
                >
                  <CPACreditTableCells
                    dealId={dealId}
                    creditObjectType={creditObjectType}
                    creditObjects={creditObjects}
                    isReadOnly
                  />
                </TableRow>
              ))}
              <TableRow key="refund-total" sx={{ backgroundColor: '#F9F9F9' }}>
                <TableCell />

                {/* Row Title */}
                <TableCell>
                  <Typography
                    fontSize="20px"
                    color="portalPalettes.darkgray"
                    fontWeight="700"
                  >
                    Total qualifying credits
                  </Typography>
                </TableCell>

                {/* Total estimated refund amount */}
                <TableCell>
                  <NumberFormat
                    thousandSeparator
                    decimalSeparator="."
                    decimalScale={2}
                    fixedDecimalScale
                    prefix="$"
                    style={{
                      fontSize: '20px',
                      color: 'portalPalettes.darkgray',
                      fontWeight: '700',
                    }}
                    displayType="text"
                    value={
                      creditObjects?.reduce(
                        (previousValue: number, creditObject) =>
                          previousValue +
                          parseFloat(
                            `${
                              creditObject.properties.estimatedRefundAmount ?? 0
                            }`,
                          ),
                        0,
                      ) ?? 0
                    }
                  />
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </>
  );
};
