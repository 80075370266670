import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import { LoadingIndicator } from 'components/LoadingIndicator/LoadingIndicator';
import { ERCADocumentTableCells } from 'components/Tables/TableCells/ERCADocumentTableCells';
import { TableCommonTypography } from 'components/Typography/TableCommonTypography';
import { TableHeaderTypography } from 'components/Typography/TableHeaderTypography';
import { ercaDocumentObjectsQuery } from 'dataLayer/Document/get';
import {
  documentNameColumnStyle,
  getDocumentTableStyles,
} from 'pages/Deals/Deal/documentsTableStyles';
import { FunctionComponent } from 'react';
import { useQuery } from 'react-query';

interface IProps {
  dealId: string;
}

export const ERCADocumentsTable: FunctionComponent<IProps> = ({ dealId }) => {
  const { dataStatusColumnStyle, statusColumnStyle, uploadDateColumnStyle } =
    getDocumentTableStyles('customObjectStyle');

  const { data: ercaDocumentObjects, isLoading: isLoadingERCADocumentObjects } =
    useQuery(ercaDocumentObjectsQuery(dealId));

  return (
    <>
      {isLoadingERCADocumentObjects ? (
        <LoadingIndicator />
      ) : (
        <TableContainer>
          <Table>
            <TableHead
              sx={{
                height: '75px',
              }}
            >
              <TableRow>
                <TableCell sx={statusColumnStyle} />
                <TableCell sx={documentNameColumnStyle}>
                  <TableHeaderTypography text="Document Name" />
                  <TableCommonTypography
                    text="Upload File Name"
                    sx={{ paddingTop: 1 }}
                  />
                </TableCell>
                <TableCell sx={uploadDateColumnStyle}>
                  <TableHeaderTypography text="Uploaded" />
                </TableCell>
                <TableCell sx={dataStatusColumnStyle}>
                  <TableHeaderTypography text="Change Status" />
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {ercaDocumentObjects?.map((ercaDocument) => (
                <TableRow
                  key={ercaDocument.documentId}
                  sx={{
                    boxShadow: '0px 1px 0px lightgray',
                  }}
                >
                  <ERCADocumentTableCells
                    ercaDocument={ercaDocument}
                    dealId={dealId}
                  />
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </>
  );
};
