import { FunctionComponent, useCallback } from 'react';
import { Autocomplete, Box, Checkbox, Chip, TextField } from '@mui/material';
import { DOCUMENT_STATUSES, ERC_YEARS } from 'helpers/constants';
import { DocumentObject, DocumentObjectProperties, S3File } from 'types';
import { UseMutationResult, useQuery } from 'react-query';
import { documentObjectsQuery } from 'dataLayer/Document/get';
import { filterAssociatedDocumentObjects } from 'helpers/utils';

interface AssociationDropdownProps {
  dealId: string;
  fileName: string;
  properties: Partial<DocumentObjectProperties>;
  selectedDocuments: DocumentObject[];
  setSelectedDocuments: (documents: DocumentObject[]) => void;
  clearAssociationList: () => void;
  updateDocumentObjectMutation: UseMutationResult<
    void,
    unknown,
    {
      documentIds: string[];
      documentProperties: Partial<DocumentObjectProperties>;
    },
    {
      previousDocumentObjects: DocumentObject[] | undefined;
    }
  >;
  isReadOnly?: boolean;
}

const AssociationsDropdownMenu: FunctionComponent<AssociationDropdownProps> = ({
  dealId,
  fileName,
  properties,
  selectedDocuments,
  setSelectedDocuments,
  clearAssociationList,
  updateDocumentObjectMutation,
  isReadOnly,
}) => {
  const { data: documentObjects } = useQuery(documentObjectsQuery(dealId));

  const customObjectAssociations = filterAssociatedDocumentObjects(
    documentObjects,
    fileName,
  );

  const updateAssociationList = useCallback(() => {
    // add: documents that are selected but not yet associated
    const documentIdsToAssociate = selectedDocuments
      .filter(
        (selected) =>
          !customObjectAssociations?.find(
            (associated) => associated.id === selected.id,
          ),
      )
      .map((document) => document.id);

    if (documentIdsToAssociate?.length) {
      updateDocumentObjectMutation.mutate({
        documentIds: documentIdsToAssociate,
        documentProperties: properties,
      });
    }

    // remove: documents were previously associated but no longer selected
    const documentIdsToUnassociate = customObjectAssociations
      ?.filter(
        (associated) =>
          !selectedDocuments.find((selected) => selected.id === associated.id),
      )
      .map((document) => document.id);

    if (documentIdsToUnassociate?.length) {
      updateDocumentObjectMutation.mutate({
        documentIds: documentIdsToUnassociate,
        documentProperties: {
          s3FileName: '',
          status: DOCUMENT_STATUSES.NOT_YET_RECEIVED,
          fileLastModifiedDate: '',
        },
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customObjectAssociations, selectedDocuments]);

  return (
    <Autocomplete
      multiple
      disabled={isReadOnly}
      disableCloseOnSelect
      size="small"
      limitTags={0}
      options={
        documentObjects?.filter((document) =>
          ERC_YEARS.includes(document.properties.year),
        ) ?? []
      }
      value={selectedDocuments}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      onClose={updateAssociationList}
      onChange={(_event, value, reason) => {
        setSelectedDocuments(value);
        if (reason === 'clear') {
          clearAssociationList();
        }
      }}
      getOptionLabel={(option) => option.properties.displayName}
      renderOption={(props, option, { selected }) => (
        <li {...props}>
          <Checkbox sx={{ marginRight: 2 }} checked={selected} />
          {option.properties.displayName}
        </li>
      )}
      renderTags={(tagValue, getTagProps) => (
        <Box>
          <Chip
            {...getTagProps({ index: 0 })}
            onDelete={undefined}
            size="small"
            label={tagValue?.[0].properties.displayName}
          />
          {tagValue.length > 1 && (
            <Chip size="small" label={`+${tagValue.length - 1}`} />
          )}
        </Box>
      )}
      renderInput={(params) => (
        <TextField {...params} placeholder="Data Set(s) Association" />
      )}
    />
  );
};

interface AssociationProps {
  dealId: string;
  selectedDocuments: DocumentObject[];
  setSelectedDocuments: (documents: DocumentObject[]) => void;
  updateDocumentObjectMutation: UseMutationResult<
    void,
    unknown,
    {
      documentIds: string[];
      documentProperties: Partial<DocumentObjectProperties>;
    },
    {
      previousDocumentObjects: DocumentObject[] | undefined;
    }
  >;
}

interface DocumentAssociationProps extends AssociationProps {
  documentObject: DocumentObject;
  clearAssociationList: () => void;
  isReadOnly?: boolean;
}

/**
 * Used for associating a HubSpot custom document to one or more documents
 */
export const DataAssociationsDropdownMenu: FunctionComponent<DocumentAssociationProps> =
  ({
    dealId,
    documentObject,
    selectedDocuments,
    setSelectedDocuments,
    clearAssociationList,
    updateDocumentObjectMutation,
    isReadOnly,
  }) => (
    <AssociationsDropdownMenu
      dealId={dealId}
      fileName={documentObject.properties.s3FileName ?? ''}
      properties={{
        s3FileName: documentObject.properties.s3FileName,
        status: documentObject.properties.status,
        invalidReason: documentObject.properties.invalidReason,
        internalNotes: documentObject.properties.internalNotes,
      }}
      selectedDocuments={selectedDocuments}
      setSelectedDocuments={setSelectedDocuments}
      clearAssociationList={clearAssociationList}
      updateDocumentObjectMutation={updateDocumentObjectMutation}
      isReadOnly={isReadOnly}
    />
  );

interface FileAssociationsProps extends AssociationProps {
  file: S3File;
}

/**
 * Used for associating an S3 file to one or more documents
 */
export const FileAssociationsDropdownMenu: FunctionComponent<FileAssociationsProps> =
  ({
    dealId,
    file,
    selectedDocuments,
    setSelectedDocuments,
    updateDocumentObjectMutation,
  }) => {
    const { data: documentObjects } = useQuery(documentObjectsQuery(dealId));

    const customObjectAssociations = filterAssociatedDocumentObjects(
      documentObjects,
      file.name,
    );

    const clearAssociationList = useCallback(() => {
      setSelectedDocuments([]);
      if (customObjectAssociations?.length) {
        updateDocumentObjectMutation.mutate({
          documentIds: customObjectAssociations?.map((object) => object.id),
          documentProperties: {
            s3FileName: '',
            fileLastModifiedDate: '',
            status: DOCUMENT_STATUSES.NOT_YET_RECEIVED,
          },
        });
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [customObjectAssociations]);

    return (
      <AssociationsDropdownMenu
        dealId={dealId}
        fileName={file.name}
        properties={{
          s3FileName: file.name,
          status: DOCUMENT_STATUSES.PENDING_REVIEW,
        }}
        selectedDocuments={selectedDocuments}
        setSelectedDocuments={setSelectedDocuments}
        clearAssociationList={clearAssociationList}
        updateDocumentObjectMutation={updateDocumentObjectMutation}
      />
    );
  };
