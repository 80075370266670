import { getUserToken } from 'dataLayer/Cognito';
import { generateHeaders, lambdaAxios } from 'helpers/utils';
import { CreditObject, CreditObjectProperties } from 'types';

const HUBSPOT_CREDITS_URL = '/hubspot/credits';

export const createCreditObject = async (
  dealId: string,
  creditProperties: Partial<CreditObjectProperties>,
) => {
  const token = await getUserToken();
  if (!token) return;

  await lambdaAxios.put(
    `${HUBSPOT_CREDITS_URL}/${dealId}`,
    creditProperties,
    generateHeaders(token),
  );
};

export const getCreditObjects = async (dealId: string) => {
  const token = await getUserToken();
  if (!token) return [];

  const response = await lambdaAxios.get<CreditObject[]>(
    `${HUBSPOT_CREDITS_URL}/${dealId}`,
    generateHeaders(token),
  );
  return response.data;
};

export const updateCreditObjects = async (
  creditObjectIds: string[],
  creditProperties: Partial<CreditObjectProperties>,
) => {
  const token = await getUserToken();
  if (!token) return;
  if (creditObjectIds.length === 0) return;

  const url = `${HUBSPOT_CREDITS_URL}?hubspotObjectId=${creditObjectIds.join(
    '&hubspotObjectId=',
  )}`;

  await lambdaAxios.post(url, creditProperties, generateHeaders(token));
};

export const deleteCreditObject = async (dealId: string, creditId: string) => {
  const token = await getUserToken();
  if (!token) return;

  await lambdaAxios.delete(
    `${HUBSPOT_CREDITS_URL}/${dealId}/${creditId}`,
    generateHeaders(token),
  );
};

// TODO: unused while nanonets disabled
// eslint-disable-next-line canonical/no-unused-exports
export const confirmCreditAmounts = async (
  requests: {
    creditId: string;
    hubspotDocId: string;
    hubspotDocName: string;
    s3FileName: string;
  }[],
  dealId: string,
) => {
  const token = await getUserToken();
  if (!token) return;

  await lambdaAxios.post(
    `/nanonets/${dealId}/credits/confirm`,
    requests,
    generateHeaders(token),
  );
};
